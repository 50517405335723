import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { isLoadingAtom } from '../store/atom';
import { useGenerateQuiz } from '../SigninPages/Dashboard/Admin/helpers.tsx';
import { selectedChapterAtom, unsavedChangesAtom } from '../store/atom';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Slider } from "@/components/ui/slider";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Wand2 } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Trans } from "@lingui/react/macro";

interface Video {
  url: string;
  name: string;
  type: 'vimeo' | 'youtube';
  transcript?: string;
}

interface VideoComponentIframeProps {
  video: Video;
}

const VideoComponentIframe = ({video}: VideoComponentIframeProps) => {
    return (
      <div className="relative w-full aspect-video">
        <iframe
          title="Vidéo du cours" 
          className="absolute top-0 left-0 w-full h-full rounded-xl shadow-lg"
          src={video.url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    )
}

interface Chapter {
  id: number;
  video?: Video;
  name?: string;
  description?: string;
  questions?: any[];
}

interface VideoSectionProps {
  selectedChapter: Chapter | null;
  saveChanges: () => void;
  unsavedChanges: boolean;
  setIsVideoModalOpen: (open: boolean) => void;
}

const VideoSection = ({ selectedChapter, saveChanges, unsavedChanges,  setIsVideoModalOpen }: VideoSectionProps) => {
    const [isLoading] = useAtom(isLoadingAtom);
    const generateQuiz = useGenerateQuiz();
    const [chapter] = useAtom(selectedChapterAtom);
    const [, setSelectedChapter] = useAtom(selectedChapterAtom);
    const [, setUnsavedChanges] = useAtom(unsavedChangesAtom);
    const [isQuizDialogOpen, setIsQuizDialogOpen] = useState(false);
    const [useVideoChecked, setUseVideoChecked] = useState(false);
    const [useDescriptionChecked, setUseDescriptionChecked] = useState(false);
    const [numQuestions, setNumQuestions] = useState(4);
    const [isGenerating, setIsGenerating] = useState(false);
    const [additionalInstructions, setAdditionalInstructions] = useState('');

    const has_transcript = chapter?.video?.transcript;
    const hasQuestions = selectedChapter?.questions?.length > 0;

    const handleGenerateQuiz = async () => {
      try {
        setIsGenerating(true);
        const questions = await generateQuiz({
          use_video: useVideoChecked,
          use_description: useDescriptionChecked,
          num_questions: numQuestions,
          additional_instructions: additionalInstructions
        });
        
        setSelectedChapter({
          ...selectedChapter,
          questions
        });
        setUnsavedChanges(true);
        setIsQuizDialogOpen(false);
      } catch (err) {
        console.error('Error generating quiz:', err);
      } finally {
        setIsGenerating(false);
      }
    };

    return (
      <div className="w-full px-6 py-4">
        <Card>
          <CardHeader>
            <CardTitle><Trans>Vidéo du module</Trans></CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex gap-8">
              <div className="w-2/3">
                {selectedChapter?.video?.name ? (
                  <div>
                    <VideoComponentIframe video={selectedChapter.video} />
                  </div>
                ) : (
                  <div className="bg-muted rounded-xl p-8 text-center h-full flex items-center justify-center">
                    <p className="text-muted-foreground"><Trans>Aucune vidéo n&apos;a été ajoutée pour ce module</Trans></p>
                  </div>
                )}
              </div>

              <div className="w-1/3 space-y-4">
                <Button
                  onClick={() => setIsVideoModalOpen(true)}
                  disabled={isLoading}
                  variant={isLoading ? "secondary" : "default"}
                  className="w-full"
                >
                  {isLoading ? <Trans>Mise à jour...</Trans> : (selectedChapter?.video ? <Trans>Modifier la vidéo</Trans> : <Trans>Ajouter une vidéo</Trans>)}
                </Button>

                <Dialog open={isQuizDialogOpen} onOpenChange={setIsQuizDialogOpen}>
                  <DialogTrigger asChild>
                    <Button className="w-full gap-2">
                      <Wand2 className="h-4 w-4" />
                      {hasQuestions ? <Trans>Améliorer Quiz</Trans> : <Trans>Générer Quiz</Trans>}
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle><Trans>Générer un Quiz</Trans></DialogTitle>
                      <DialogDescription>
                        <Trans>Choisissez vos options de génération</Trans>
                      </DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4 py-4">
                      <div className="flex items-center space-x-2">
                        <Checkbox 
                          id="useVideo" 
                          checked={useVideoChecked}
                          onCheckedChange={(checked) => {
                            if (checked) {
                              setUseVideoChecked(true);
                              setUseDescriptionChecked(false);
                            }
                          }}
                          disabled={!has_transcript}
                        />
                        <Label htmlFor="useVideo" className="flex items-center">
                          <Trans>Générer depuis la transcription de la vidéo</Trans>
                          {!has_transcript && (
                            <span className="ml-2 text-xs text-red-500"><Trans>(Transcription indisponible)</Trans></span>
                          )}
                        </Label>
                      </div>

                      <div className="flex items-center space-x-2">
                        <Checkbox
                          id="useDescription"
                          checked={useDescriptionChecked}
                          onCheckedChange={(checked) => {
                            if (checked) {
                              setUseDescriptionChecked(true);
                              setUseVideoChecked(false);
                            }
                          }}
                          disabled={!selectedChapter?.description}
                        />
                        <Label htmlFor="useDescription" className="flex items-center justify-between w-full">
                          <span><Trans>Générer depuis la description du module</Trans></span>
                          {selectedChapter?.description ? (
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button variant="ghost" size="sm" className="ml-2">
                                  <Trans>Voir</Trans>
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent>
                                <p className="text-sm">{selectedChapter?.description}</p>
                              </PopoverContent>
                            </Popover>
                          ) : (
                            <span className="text-xs text-red-500"><Trans>(Description indisponible)</Trans></span>
                          )}
                        </Label>
                      </div>

                      <div className="space-y-2">
                        <Label><Trans>Nombre de questions ({numQuestions})</Trans></Label>
                        <Slider
                          value={[numQuestions]}
                          onValueChange={(value) => setNumQuestions(value[0])}
                          min={1}
                          max={12}
                          step={1}
                        />
                      </div>

                      <div className="space-y-2">
                        <Label><Trans>Instructions additionnelles</Trans></Label>
                        <Textarea
                          value={additionalInstructions}
                          onChange={(e) => setAdditionalInstructions(e.target.value)}
                          placeholder="Ex: Créer des questions progressives, du plus simple au plus complexe..."
                        />
                      </div>
                    </div>

                    <DialogFooter>
                      <Button 
                        onClick={handleGenerateQuiz} 
                        disabled={(!useVideoChecked && !useDescriptionChecked) || isGenerating}
                      >
                        {isGenerating ? <Trans>Génération en cours...</Trans> : <Trans>Générer</Trans>}
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
      
                <Button
                  onClick={saveChanges}
                  disabled={!unsavedChanges || isLoading}
                  variant={unsavedChanges && !isLoading ? "default" : "secondary"}
                  className="w-full"
                >
                  {isLoading ? <Trans>Sauvegarde...</Trans> : <Trans>Sauvegarder</Trans>}
                </Button>
      
              
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

export default VideoSection;