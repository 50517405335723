import { useEffect, useRef } from 'react';
import Reveal from 'reveal.js';
import 'reveal.js/dist/reveal.css';
import 'reveal.js/dist/theme/black.css';
import RevealHighlight from 'reveal.js/plugin/highlight/highlight';

const Slides = () => {
  const deckDivRef = useRef(null);
  const deckRef = useRef(null);

  useEffect(() => {
    if (deckRef.current) return;

    deckRef.current = new Reveal(deckDivRef.current, {
      transition: 'slide',
      controls: true, // Show control arrows
      controlsLayout: 'edges', // Position controls at edges
      controlsBackArrows: 'visible', // Show back arrow
      navigationMode: 'linear', // Linear navigation between slides
      progress: true,
      center: true,
      hash: true,
      keyboard: true, // Enable keyboard navigation
      overview: true, // Enable slide overview
      touch: true, // Enable touch navigation
      slideNumber: true, // Show slide numbers
      plugins: [RevealHighlight],
    //   fragments: true,
    });

    deckRef.current.initialize().then(() => {
      // Add any event handlers here
      console.log("The deck is initialized");
    });

    return () => {
      try {
        if (deckRef.current) {
          deckRef.current.destroy();
          deckRef.current = null;
        }
      } catch (e) {
        console.warn('Reveal.js destroy call failed.');
      }
    };
  }, []);

  return (
    <div className="container mx-auto p-4">
      <div className="rounded-lg shadow-lg p-4 h-[calc(100vh-2rem)]">

        <div className="reveal border border-gray-300" ref={deckDivRef}>
          <div className="slides">
            <section>
              <h2>Welcome to Python Programming</h2>
              <p>Let's explore the fundamentals of Python together!</p>
              <aside className="notes">
                Introduction slide - Set expectations for the lesson
              </aside>
            </section>

            <section>
              <h2>What is Python?</h2>
              <ul>
                <li>High-level programming language</li>
                <li>Easy to read and write</li>
                <li>Great for beginners</li>
                <li>Used in web development, data science, AI and more!</li>
              </ul>
            </section>

            <section>
              <h2>Your First Python Program</h2>
              <pre><code className="python">
print("Hello, World!")
              </code></pre>
              <p>This prints: Hello, World!</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slides;
