import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { AlertTriangle } from "lucide-react";

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => (
  <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent className="sm:max-w-lg">
      <div className="text-center">
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-destructive/10 mb-4">
          <AlertTriangle className="h-6 w-6 text-destructive" />
        </div>
        <DialogHeader>
          <DialogTitle className="text-2xl">Supprimer l'exercice</DialogTitle>
        </DialogHeader>
        <p className="text-muted-foreground mb-6">
          Êtes-vous sûr de vouloir supprimer cet exercice ? Cette action est irréversible.
        </p>
        <div className="flex justify-center space-x-4">
          <Button
            variant="outline"
            onClick={onClose}
          >
            Annuler
          </Button>
          <Button
            variant="destructive"
            onClick={onConfirm}
          >
            Supprimer
          </Button>
        </div>
      </div>
    </DialogContent>
  </Dialog>
);

export default DeleteConfirmationModal;