import { useState } from 'react';
import React from 'react';
import {
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { generateQuiz, improveExercice } from 'store/actions/requests';
import { useAtom } from 'jotai';
import { selectedChapterAtom, isLoadingAtom } from '../../../store/atom';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Trans } from "@lingui/macro";

export const useGenerateQuiz = () => {
  const [selectedChapter] = useAtom(selectedChapterAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);
  return async (options: {
    use_video: boolean;       // Whether to use video transcript for generation
    use_description: boolean; // Whether to use chapter description for generation
    num_questions?: number;   // Number of questions to generate (default: 4)
    additional_instructions?: string; // Additional instructions for the quiz generation
  }) => {
    const { use_video, use_description, num_questions, additional_instructions } = options;

    try {
      setIsLoading(true);
      const response = await generateQuiz({
        video_id: selectedChapter.video?.id,
        use_video,
        use_description,
        num_questions,
        additional_instructions,
        chapter_id: selectedChapter.id
      });

      return response.data;

    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };
};

export const useGenerateExercise = () => {
  const [, setIsLoading] = useAtom(isLoadingAtom);

  return async (description: string, context: string, exerciceId: number) => {
    
    try {
      setIsLoading(true);
      const response = await improveExercice(exerciceId, { description, context });
      return response.data.exercice;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };
};

interface SortableItemProps {
  id: string;
  children: React.ReactNode;
}

const SortableItem = ({ id, children }: SortableItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

interface CreateSectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateSection: (name: string) => Promise<void>;
}

const CreateSectionModal = ({ isOpen, onClose, onCreateSection }: CreateSectionModalProps) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await onCreateSection(name);
      setName('');
      setError('');
      onClose();
    } catch (err) {
      setError(err.message || <Trans>Une erreur est survenue lors de la création de la section</Trans>);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle><Trans>Nouvelle Section</Trans></DialogTitle>
          <DialogDescription>
            <Trans>Créez une nouvelle section</Trans>
          </DialogDescription>
        </DialogHeader>

        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <form onSubmit={(handleSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="sectionName"><Trans>Nom de la section</Trans></Label>
            <Input
              id="sectionName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={onClose}>
              <Trans>Annuler</Trans>
            </Button>
            <Button type="submit">
              <Trans>Créer</Trans>
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  itemName: string;
  itemType: string;
  affectedItems: any[];
}

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, itemName, itemType, affectedItems }: DeleteConfirmationModalProps) => (
  <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle><Trans>Suppression de {itemType}</Trans></DialogTitle>
        <DialogDescription>
          <Trans>Voulez-vous vraiment supprimer {itemName} ?</Trans>
        </DialogDescription>
      </DialogHeader>

      {affectedItems && affectedItems.length > 0 && (
        <div className="space-y-2">
          <p className="text-destructive font-medium"><Trans>Cette action supprimera également :</Trans></p>
          <ul className="list-disc list-inside space-y-1 text-muted-foreground">
            {affectedItems.map((item, index) => (
              <li key={index}>
                {itemType === 'section' ? (
                  <Trans>Module &quot;{item.name}&quot; ({item.questions} questions)</Trans>
                ) : (
                  <Trans>Question: &quot;{item.text}&quot; ({item.answers} réponses)</Trans>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      <DialogFooter>
        <Button variant="outline" onClick={onClose}>
          <Trans>Annuler</Trans>
        </Button>
        <Button variant="destructive" onClick={onConfirm}>
          <Trans>Confirmer</Trans>
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

export {
  SortableItem,
  CreateSectionModal,
  DeleteConfirmationModal
};
