import * as requests from 'store/actions/requests';
import { Mixpanel } from '../MixPanel';

export const insertUrlParam = (key, value) => {
  if (window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${searchParams.toString()}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
};

const storageHelper = {
  set: (key, value) => {
    return localStorage.setItem(key, value);
  },
  remove: (key) => {
    return localStorage.removeItem(key);
  },
  get: (key) => {
    return localStorage.getItem(key);
  },
};

export default storageHelper;

export const getUlrSvg = (svgString) => {
  const blob = new Blob([svgString], {
    type: 'image/svg+xml',
  });
  return URL.createObjectURL(blob);
};

export const getFirstExercice = (chapter) => {
  if (chapter.exercices && chapter.exercices.length) return chapter.exercices[0].id;
};

export const visitExercice = (history, exercice_id, universIdentifier, open_video) => {
  history.push(`/${universIdentifier}/exercice/${exercice_id}?open_video=${open_video}`);
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const handleClickPay = (stripe, interval) => {
  Mixpanel.track(`subscription attempt`);
  requests.createCheckoutSession(interval || 'month').then((res) => {
    stripe.redirectToCheckout({ sessionId: res.data.session_id });
  });
};

export const getFeedbacks = (files_attempts) => {
  const fileAttempt = files_attempts[0]; // only one file is expected for feedbacks, or even the attempt object directly
  const feedbacks = fileAttempt.feedbacks;
  return feedbacks;
};

export const showPayment = (user) => {
  return user && !user.is_premium && !user.profile.school && !user.is_staff && !user.is_superuser;
};
