msgid ""
msgstr ""
"POT-Creation-Date: 2025-01-25 17:51+0100\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: en\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

#: src/components/VideoSection.tsx:188
msgid "(Description indisponible)"
msgstr "(Description unavailable)"

#: src/components/VideoSection.tsx:157
msgid "(Transcription indisponible)"
msgstr "(Transcription unavailable)"

#. placeholder {0}: supChapter.name
#: src/SigninPages/Universe/ChooseSection/index.jsx:66
msgid "{0}"
msgstr "{0}"

#. placeholder {0}: subscriptionTrack.time_month
#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:29
#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:72
msgid "{0} mois"
msgstr "{0} months"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:238
#~ msgid "{0} Suivant"
#~ msgstr "Next {0}"

#. placeholder {0}: supChapter.percent_completed
#: src/SigninPages/Universe/ChooseSection/index.jsx:54
msgid "{0}%"
msgstr "{0}%"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:129
msgid "{fileError}"
msgstr ""

#: src/SigninPages/Universe/Card/index.tsx:143
msgid "{numberExercices} exercices"
msgstr "{numberExercices} exercises"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:280
msgid "{numExercises} exercices ont été générés avec succès"
msgstr "{numExercises} exercises have been generated successfully"

#: src/SigninPages/Dashboard/Admin/Components.tsx:75
msgid "Actions"
msgstr "Actions"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:398
#~ msgid "Ajouter un chapitre"
#~ msgstr "Add a chapter"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:137
msgid "Ajouter un exercice"
msgstr "Add an exercise"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:398
msgid "Ajouter un module"
msgstr "Add a module"

#: src/SigninPages/Dashboard/Admin/Components.tsx:237
msgid "Ajouter une nouvelle question"
msgstr "Add a new question"

#: src/SigninPages/Dashboard/Admin/Components.tsx:64
msgid "Ajouter une réponse"
msgstr "Add an answer"

#: src/components/VideoSection.tsx:124
#: src/SigninPages/Dashboard/Admin/SideBar.tsx:154
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:30
msgid "Ajouter une vidéo"
msgstr "Add a video"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:309
#~ msgid "Ajoutez des instructions spécifiques pour améliorer l'exercice..."
#~ msgstr "Add specific instructions to improve the exercise..."

#: src/SigninPages/Universe/EndToEndFullScreenLayout.tsx:36
#: src/SigninPages/Universe/EndToEndFullScreenLayout.tsx:47
msgid "Aller à la suite"
msgstr "Continue to next"

#: src/SigninPages/Universe/EndToEndFullScreenLayout.tsx:43
msgid "Aller au QCM"
msgstr "Go to Quiz"

#: src/SigninPages/Universe/EndToEndFullScreenLayout.tsx:45
msgid "Aller aux exercices"
msgstr "Go to exercises"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:161
msgid "Améliorer"
msgstr "Improve"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:301
msgid "Améliorer l'exercice"
msgstr "Improve exercise"

#: src/components/VideoSection.tsx:131
msgid "Améliorer Quiz"
msgstr "Improve Quiz"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:84
#: src/SigninPages/Dashboard/Admin/helpers.tsx:142
#: src/SigninPages/Dashboard/Admin/helpers.tsx:192
#: src/SigninPages/Dashboard/Admin/SideBar.tsx:166
#: src/SigninPages/Dashboard/Admin/SideBar.tsx:348
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:319
#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:141
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:89
msgid "Annuler"
msgstr "Cancel"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:20
msgid "Attention, confirmez bien avec votre responsable avant de commencer la formation. Un retour en arrière n'est pas possible."
msgstr "Warning: please confirm with your supervisor before starting the training. This action cannot be undone."

#: src/components/Quiz/Quiz.tsx:142
msgid "Attention: plusieurs réponses possibles par question."
msgstr "Warning: multiple answers possible per question."

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:154
msgid "Aucun ID univers fourni"
msgstr "No universe ID provided"

#: src/SigninPages/Dashboard/Admin/Components.tsx:313
msgid "Aucun nom défini"
msgstr "No name defined"

#: src/SigninPages/Dashboard/Admin/Components.tsx:348
msgid "Aucune description définie"
msgstr "No description defined"

#: src/SigninPages/Dashboard/Admin/Components.tsx:335
msgid "Aucune ressource définie"
msgstr "No resource defined"

#: src/components/VideoSection.tsx:112
#~ msgid "Aucune vidéo n'a été ajoutée pour ce chapitre"
#~ msgstr "No video has been added for this chapter"

#: src/components/VideoSection.tsx:112
msgid "Aucune vidéo n'a été ajoutée pour ce module"
msgstr "No video has been added for this module"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:256
msgid "Avant de regarder la solution es-tu sûr d'avoir tout essayé ?"
msgstr "Before looking at the solution, are you sure you've tried everything?"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:91
msgid "BACK"
msgstr "BACK"

#: src/SigninPages/DynamicFooter/index.jsx:31
msgid "Besoin d'aide ?"
msgstr "Need help?"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:260
msgid "C'est en se trompant, puis en réessayant que l'on progresse le plus."
msgstr "Making mistakes and trying again is how we learn the most."

#: src/SigninPages/Universe/Card/index.tsx:150
#~ msgid "Ce chapitre contient :"
#~ msgstr "This chapter contains:"

#: src/components/ModalTrialEnd/ModalTrialEnd.tsx:27
#~ msgid "Ce chapitre n'est pas disponible pour la version d'essai. N'hésites pas a consulter nos offres."
#~ msgstr "This chapter is not available in the trial version. Feel free to check our offers."

#: src/SigninPages/Universe/Card/index.tsx:150
msgid "Ce module contient :"
msgstr "This module contains:"

#: src/components/ModalTrialEnd/ModalTrialEnd.tsx:27
msgid "Ce module n'est pas disponible pour la version d'essai. N'hésites pas a consulter nos offres."
msgstr "This module is not available in the trial version. Feel free to check our offers."

#: src/components/ModalTrialEnd/ModalTrialEnd.tsx:15
msgid "Ceci est une version d'essai"
msgstr "This is a trial version"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:175
msgid "Cette action supprimera également :"
msgstr "This action will also delete:"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:73
msgid "Cette formation contient les cours suivants:"
msgstr "This training contains the following courses:"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:238
#~ msgid "Chapitre Suivant"
#~ msgstr "Next Chapter"

#: src/SigninPages/Universe/Course/index.tsx:68
#: src/SigninPages/Dashboard/Admin/Components.tsx:376
#~ msgid "Chapitres"
#~ msgstr "Chapters"

#: src/components/VideoSection.tsx:138
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:344
msgid "Choisissez vos options de génération"
msgstr "Choose your generation options"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:210
msgid "Code de départ:"
msgstr "Starting code:"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:52
msgid "Code Solution"
msgstr "Solution Code"

#. placeholder {0}: subscriptionTrack.name
#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:55
msgid "Commencer la formation {0}"
msgstr "Start the training {0}"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:62
msgid "Confirmation de démarrage de la formation"
msgstr "Confirmation of training start"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:195
msgid "Confirmer"
msgstr "Confirm"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:87
msgid "Confirmer le démarrage"
msgstr "Confirm start"

#: src/components/ModalTrialEnd/ModalTrialEnd.tsx:47
msgid "Continuer la démo"
msgstr "Continue the demo"

#: src/SigninPages/DynamicFooter/index.jsx:111
msgid "Copyright"
msgstr "Copyright"

#: src/SigninPages/Dashboard/Admin/Components.tsx:73
msgid "Correcte"
msgstr "Correct"

#: src/SigninPages/Dashboard/Road.tsx:204
msgid "Cours"
msgstr "Course"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:145
#: src/SigninPages/Dashboard/Admin/SideBar.tsx:171
msgid "Créer"
msgstr "Create"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:119
msgid "Créez une nouvelle section"
msgstr "Create a new section"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:70
msgid "Date de début:"
msgstr "Start date:"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:71
msgid "Date de fin estimée:"
msgstr "Estimated end date:"

#: src/SigninPages/Header/index.jsx:119
#: src/SigninPages/Dashboard/Road.tsx:187
msgid "Déconnexion"
msgstr "Logout"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:20
#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:68
#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:91
#: src/SigninPages/Dashboard/Admin/Components.tsx:266
#: src/SigninPages/Dashboard/Admin/Components.tsx:342
msgid "Description"
msgstr "Description"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:134
msgid "Description du module"
msgstr "Module description"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:396
msgid "Description indisponible"
msgstr "Description unavailable"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:73
msgid "Description manquante"
msgstr "Missing description"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:141
msgid "Description utilisée comme contexte pour les exercices..."
msgstr "Description used as context for exercises..."

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:28
msgid "Durée"
msgstr "Duration"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:72
msgid "Durée de la formation:"
msgstr "Training duration:"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:148
msgid "Enregistrement..."
msgstr "Saving..."

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:148
msgid "Enregistrer"
msgstr "Save"

#: src/SigninPages/Dashboard/Admin/Components.tsx:47
msgid "Entrez le texte de la question..."
msgstr "Enter the question text..."

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:100
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:123
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:135
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:173
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:229
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:253
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:287
msgid "Erreur"
msgstr "Error"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:174
msgid "Erreur lors de la création de l'exercice"
msgstr "Error creating exercise"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:101
msgid "Erreur lors de la génération de l'exercice"
msgstr "Error generating exercise"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:288
msgid "Erreur lors de la génération des exercices"
msgstr "Error generating exercises"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:200
msgid "Erreur lors de la mise à jour de la vidéo"
msgstr "Error updating video"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:124
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:283
msgid "Erreur lors de la sauvegarde"
msgstr "Error while saving"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:230
msgid "Erreur lors de la suppression"
msgstr "Error while deleting"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:281
msgid "Essayer encore"
msgstr "Try again"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:66
msgid "Êtes-vous sûr de vouloir commencer cette formation maintenant ? Cette action est irréversible."
msgstr "Are you sure you want to start this training now? This action is irreversible."

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:438
#~ msgid "Ex: Créer des exercices progressifs, du plus simple au plus complexe..."
#~ msgstr "Ex: Create progressive exercises, from simplest to most complex..."

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:93
msgid "Exercice généré"
msgstr "Exercise generated"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:119
msgid "Exercice sauvegardé avec succés!"
msgstr "Exercise saved successfully!"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:238
msgid "Exercice Suivant"
msgstr "Next Exercise"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:225
msgctxt "Exercice supprimé avec succès"
msgid "Exercice supprimé avec succès"
msgstr "Exercise deleted successfully"

#: src/SigninPages/Dashboard/Admin/Components.tsx:258
msgid "Exercices"
msgstr "Exercises"

#: src/SigninPages/Dashboard/Admin/Components.tsx:52
msgid "Explication"
msgstr "Explanation"

#: src/components/Quiz/Report.tsx:105
msgid "Explication pour la question "
msgstr "Explanation for question"

#: src/SigninPages/Universe/FullScreenLayout.tsx:42
msgid "Fermer"
msgstr "Close"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:108
msgid "Fichier actuel"
msgstr "Current file"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:34
msgid "Formations incluses:"
msgstr "Included trainings:"

#: src/components/VideoSection.tsx:219
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:448
msgid "Génération en cours..."
msgstr "Generating..."

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:325
msgid "Génération..."
msgstr "Generating..."

#: src/components/VideoSection.tsx:219
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:325
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:448
msgid "Générer"
msgstr "Generate"

#: src/components/VideoSection.tsx:175
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:383
#~ msgid "Générer depuis la description du chapitre"
#~ msgstr "Generate from chapter description"

#: src/components/VideoSection.tsx:175
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:383
msgid "Générer depuis la description du module"
msgstr ""

#: src/components/VideoSection.tsx:155
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:362
msgid "Générer depuis la transcription de la vidéo"
msgstr "Generate from video transcription"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:415
#~ msgid "Générer depuis le PDF du chapitre"
#~ msgstr "Generate from chapter PDF"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:415
msgid "Générer depuis le PDF du module"
msgstr ""

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:342
msgid "Générer des exercices en lot"
msgstr "Generate exercises in batch"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:338
msgid "Générer exercices en lot"
msgstr "Generate exercises in batch"

#: src/components/VideoSection.tsx:131
msgid "Générer Quiz"
msgstr "Generate Quiz"

#: src/components/VideoSection.tsx:136
msgid "Générer un Quiz"
msgstr "Generate a Quiz"

#: src/SigninPages/Universe/Card/index.tsx:124
msgid "Gratuit"
msgstr "Free"

#: src/SigninPages/Exercise/MainExercise/SideBox/ExerciseHeader/ExerciseHeader.tsx:34
#: src/SigninPages/Exercise/MainExercise/SideBox/ExerciseHeader/ExerciseHeader.tsx:39
msgid "inconnue"
msgstr "unknown"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:65
msgid "Indices"
msgstr "Hints"

#: src/SigninPages/Dashboard/Admin/Components.tsx:298
msgid "Informations du module"
msgstr "Module information"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:39
#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:78
msgid "Instructions"
msgstr "Instructions"

#: src/components/VideoSection.tsx:205
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:434
msgid "Instructions additionnelles"
msgstr "Additional instructions"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:83
msgid "Instructions manquantes"
msgstr "Missing instructions"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:305
msgid "Instructions supplémentaires"
msgstr "Additional instructions"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:94
msgid "L'exercice a été généré avec succès"
msgstr "The exercise was generated successfully"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:508
msgid "la section"
msgstr "the section"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:199
msgid "LANCER LE CODE"
msgstr "RUN CODE"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:508
#~ msgid "le chapitre"
#~ msgstr "the chapter"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:508
msgid "le module"
msgstr ""

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:86
msgid "Le nom doit contenir au moins 2 caractères."
msgstr ""

#: src/components/Quiz/Report.tsx:61
msgid "Ma Réponse"
msgstr "My Answer"

#: src/SigninPages/Dashboard/Road.tsx:224
msgid "Mes Formations"
msgstr ""

#: src/components/VideoSection.tsx:124
msgid "Mise à jour..."
msgstr "Updating..."

#: src/components/VideoSection.tsx:124
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:30
msgid "Modifier la vidéo"
msgstr "Edit video"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:72
msgid "Modifier le Module"
msgstr ""

#: src/SigninPages/Dashboard/Admin/Components.tsx:304
msgid "Modifier les informations supplémentaires"
msgstr "Edit additional information"

#. placeholder {0}: item.name
#. placeholder {1}: item.questions
#: src/SigninPages/Dashboard/Admin/helpers.tsx:180
msgid "Module \"{0}\" ({1} questions)"
msgstr ""

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:238
msgid "Module Suivant"
msgstr ""

#: src/SigninPages/Universe/Course/index.tsx:68
#: src/SigninPages/Dashboard/Admin/Components.tsx:376
msgid "Modules"
msgstr ""

#: src/SigninPages/Dashboard/Admin/helpers.tsx:131
msgid "Nom de la section"
msgstr ""

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:123
#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:77
#: src/SigninPages/Dashboard/Admin/Components.tsx:309
msgid "Nom du module"
msgstr "Module name"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:423
msgid "Nombre d'exercices"
msgstr "Number of exercises"

#: src/components/VideoSection.tsx:194
msgid "Nombre de questions ({numQuestions})"
msgstr "Number of questions ({numQuestions})"

#: src/SigninPages/DynamicFooter/index.jsx:79
msgid "Nous appeller"
msgstr "Call us"

#: src/SigninPages/DynamicFooter/index.jsx:29
msgid "NOUS CONTACTER"
msgstr "CONTACT US"

#: src/SigninPages/DynamicFooter/index.jsx:95
msgid "NOUS SUIVRE"
msgstr "FOLLOW US"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:112
msgid "Nouveau Module"
msgstr "New Module"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:166
msgid "Nouvel exercice créé avec succès!"
msgstr "New exercise created successfully!"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:232
msgid "Nouvelle question"
msgstr "New question"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:117
#: src/SigninPages/Dashboard/Admin/SideBar.tsx:368
msgid "Nouvelle Section"
msgstr "New Section"

#: src/SigninPages/PdfFile/PDFViewer.tsx:82
msgid "Page {pageNumber} sur {numPages}"
msgstr "Page {pageNumber} of {numPages}"

#: src/SigninPages/Header/index.jsx:115
#: src/SigninPages/Dashboard/Road.tsx:184
msgid "Paramètres Paiement"
msgstr "Payment Settings"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:134
msgid "Pas encore d'exercices pour ce chapitre."
msgstr "No exercises yet for this chapter."

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:417
msgid "PDF indisponible"
msgstr "PDF unavailable"

#: src/SigninPages/PdfFile/PDFViewer.tsx:79
msgid "Précédent"
msgstr "Previous"

#: src/SigninPages/DynamicFooter/index.jsx:110
msgid "Propriété privé"
msgstr "Private property"

#. placeholder {0}: currentQuestionIndex + 1
#. placeholder {1}: questions.length
#: src/components/Quiz/Quiz.tsx:137
msgid "Question {0} sur {1}"
msgstr "Question {0} of {1}"

#. placeholder {0}: indexQuestion + 1
#: src/components/Quiz/Question.tsx:37
msgid "Question #{0}"
msgstr "Question #{0}"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:250
msgid "Question ajoutée. Appuyez sur sauvegarder pour enregistrer les changements"
msgstr "Question added. Press save to store changes"

#: src/components/Quiz/Quiz.tsx:165
msgid "Question précédente"
msgstr "Previous question"

#: src/components/Quiz/Quiz.tsx:174
msgid "Question suivante"
msgstr "Next question"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:124
msgid "Question supprimée. Appuyez sur sauvegarder pour enregistrer les changements"
msgstr "Question deleted. Press save to store changes"

#. placeholder {0}: item.text
#. placeholder {1}: item.answers
#: src/SigninPages/Dashboard/Admin/helpers.tsx:182
msgid "Question: \"{0}\" ({1} réponses)"
msgstr ""

#. placeholder {0}: selectedChapter.questions?.length || 0
#: src/SigninPages/Dashboard/Admin/Components.tsx:216
msgid "Questions: {0}"
msgstr "Questions: {0}"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:277
msgid "Quiz sauvegardé avec succès!"
msgstr "Quiz saved successfully!"

#: src/components/Quiz/Report.tsx:119
msgid "Recommencer"
msgstr "Try again"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:337
msgid "Réorganiser"
msgstr "Reorganize"

#: src/SigninPages/Dashboard/Admin/Components.tsx:74
msgid "Réponse"
msgstr "Answer"

#: src/components/Quiz/Report.tsx:62
msgid "Réponse attendue"
msgstr "Expected answer"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:142
msgid "Réponse supprimée. Appuyez sur sauvegarder pour enregistrer les changements"
msgstr "Answer deleted. Press save to store changes"

#: src/SigninPages/Dashboard/Admin/Components.tsx:320
msgid "Resource"
msgstr "Resource"

#: src/components/Quiz/Report.tsx:60
msgid "Resultat"
msgstr "Result"

#: src/SigninPages/Exercise/MainExercise/MainSqlRenderer/MainSqlRenderer.tsx:11
msgid "Résultat Requete"
msgstr "Query Result"

#: src/components/Quiz/Report.tsx:24
msgid "Résultats"
msgstr "Results"

#: src/components/VideoSection.tsx:231
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:486
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:31
msgid "Sauvegarde..."
msgstr "Saving..."

#: src/components/VideoSection.tsx:231
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:486
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:31
msgid "Sauvegarder"
msgstr "Save"

#: src/SigninPages/Exercise/MainExercise/SideBox/ExerciseHeader/ExerciseHeader.tsx:34
#: src/SigninPages/Exercise/MainExercise/SideBox/ExerciseHeader/ExerciseHeader.tsx:39
msgid "Section"
msgstr "Section"

#: src/SigninPages/Universe/Course/index.tsx:51
#: src/SigninPages/Dashboard/Admin/Components.tsx:369
msgid "Sections"
msgstr "Sections"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:115
msgid "Select an exercise"
msgstr "Select an exercise"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:193
msgid "Select file type"
msgstr "Select file type"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:69
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:74
msgid "Select video type"
msgstr "Select video type"

#: src/SigninPages/Dashboard/Admin/Components.tsx:381
#~ msgid "Sélectionnez un chapitre pour commencer à éditer son contenu"
#~ msgstr "Select a chapter to start editing its content"

#: src/SigninPages/Dashboard/Admin/Components.tsx:381
msgid "Sélectionnez un module pour commencer à éditer son contenu"
msgstr "Select a module to start editing its content"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:124
msgid "Solution"
msgstr "Solution"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:232
msgid "Solution:"
msgstr "Solution:"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:118
msgid "Succes"
msgstr "Success"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:165
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:224
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:279
msgid "Succès"
msgstr "Success"

#: src/SigninPages/PdfFile/PDFViewer.tsx:85
msgid "Suivant"
msgstr "Next"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:103
msgid "Support de cours (PDF)"
msgstr ""

#: src/SigninPages/Dashboard/Admin/helpers.tsx:167
msgid "Suppression de {itemType}"
msgstr ""

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:407
msgid "Supprimer la section"
msgstr "Delete section"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:115
msgid "Télécharger"
msgstr ""

#: src/SigninPages/Dashboard/Admin/Components.tsx:103
msgid "Texte de la réponse..."
msgstr "Answer text..."

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:364
msgid "Transcription indisponible"
msgstr "Transcription unavailable"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:56
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:61
msgid "Type de vidéo"
msgstr "Video type"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:109
msgid "Une erreur est survenue lors de la création de la section"
msgstr ""

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:104
#~ msgid "Une erreur est survenue lors de la création du chapitre"
#~ msgstr "An error occurred while creating the chapter"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:104
msgid "Une erreur est survenue lors de la création du module"
msgstr ""

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:330
msgid "Univers"
msgstr "Universe"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:38
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:43
msgid "URL / ID de la vidéo"
msgstr "Video URL / ID"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:106
msgid "Utiliser"
msgstr "Use"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:354
msgid "Valider"
msgstr "Validate"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:216
msgid "VALIDER"
msgstr "VALIDATE"

#: src/components/Quiz/Quiz.tsx:178
msgid "Valider Mes Réponses"
msgstr "Validate My Answers"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:136
#~ msgid "Veuillez sélectionner un chapitre"
#~ msgstr "Please select a chapter"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:136
msgid "Veuillez sélectionner un module"
msgstr ""

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:254
msgid "Veuillez sélectionner une source de contenu valide"
msgstr "Please select a valid content source"

#: src/SigninPages/Dashboard/Admin/Components.tsx:250
msgid "Video"
msgstr "Video"

#: src/components/VideoSection.tsx:101
msgid "Vidéo du module"
msgstr "Module video"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:192
msgid "Vidéo mise à jour avec succès"
msgstr "Video updated successfully"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:149
msgid "Visiter l'exercice"
msgstr "Visit exercise"

#: src/components/Quiz/Report.tsx:25
msgid "Voici les résultats de votre quizz."
msgstr "Here are your quiz results."

#: src/components/VideoSection.tsx:180
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:388
msgid "Voir"
msgstr "View"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:54
msgid "Voir {number} autres lignes"
msgstr "View {number} more lines"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:272
msgid "Voir la solution"
msgstr "View solution"

#: src/SigninPages/Universe/EndToEndFullScreenLayout.tsx:41
msgid "Voir la vidéo"
msgstr ""

#: src/components/ModalTrialEnd/ModalTrialEnd.tsx:39
msgid "Voir nos offres"
msgstr "View our offers"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:51
msgid "Votre code"
msgstr "Your code"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:169
msgid "Voulez-vous vraiment supprimer {itemName} ?"
msgstr ""
