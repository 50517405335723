import { ChevronRight, X } from 'lucide-react';
import { useHistory } from 'react-router-dom';
import { Trans } from "@lingui/react/macro";

type FullScreenLayoutProps = {
  children: React.ReactNode;
  onClose: () => void;
  handleNextStep: () => void;
  nextButtonText: string;
};

const FullScreenLayout: React.FC<FullScreenLayoutProps> = ({
  children,
  onClose,
  handleNextStep,
  nextButtonText,
}) => {
  const history = useHistory();

  const handleClose = () => {
    if (onClose === undefined) {
      history.goBack();
    } else {
      onClose();
    }
  };
  
  return (
    <div className="fixed inset-0 bg-black z-[1000]">
      <div className="relative w-full h-full flex flex-col">
        <div className="flex-1 relative overflow-hidden z-[1]">
          {children}
        </div>
        <div className="relative h-[60px] bg-black/95 border-t border-white/10 px-5 flex items-center z-10">
          <div className="flex gap-2.5">
            <button
              type="button"
              onClick={handleClose}
              className="flex items-center gap-2 px-4 py-2 rounded-md bg-neutral-600 text-white font-medium cursor-pointer transition-all duration-200 hover:-translate-y-0.5 hover:bg-neutral-700"
            >
              <X size={20} />
              <span className="text-sm"><Trans>Fermer</Trans></span>
            </button>
            {handleNextStep !== undefined && (
              <button
                type="button"
                onClick={handleNextStep}
                className="flex items-center gap-2 px-4 py-2 rounded-md bg-[#4a41ff] text-white font-medium cursor-pointer transition-all duration-200 hover:-translate-y-0.5 hover:bg-[#3931ff]"
              >
                <span className="text-sm">{nextButtonText}</span>
                <ChevronRight size={20} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenLayout;