import MDEditor from '@uiw/react-md-editor';
import CustomEditor from './Editor.tsx';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Plus, Trash, ExternalLink, Wand2 } from "lucide-react";
import { Trans } from "@lingui/react/macro";

interface ExerciseEditorProps {
  selectedExercise: any;
  dispatch: (action: any) => void;
}

const ExerciseEditor = ({ selectedExercise, dispatch }: ExerciseEditorProps) => (
  <div className="space-y-4">
    <div className="grid grid-cols-2 gap-4">
      <Card>
        <CardHeader className="pb-2">
          <CardTitle><Trans>Description</Trans></CardTitle>
        </CardHeader>
        <CardContent>
          <textarea
            className="w-full h-[200px] p-2 border rounded"
            value={selectedExercise.description || ''}
            onChange={(e) => dispatch({
              type: 'UPDATE_EXERCISE',
              payload: {
                ...selectedExercise,
                description: e.target.value
              }
            })}
          />
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="pb-2">
          <CardTitle><Trans>Instructions</Trans></CardTitle>
        </CardHeader>
        <CardContent>
          <textarea
            className="w-full h-[200px] p-2 border rounded"
            value={selectedExercise.instructions || ''}
            onChange={(e) => dispatch({
              type: 'UPDATE_EXERCISE',
              payload: {
                ...selectedExercise,
                instructions: e.target.value
              }
            })}
          />
        </CardContent>
      </Card>
    </div>
  </div>
);

interface ExerciseViewerProps {
  selectedExercise: any;
}

const ExerciseViewer = ({ selectedExercise }: ExerciseViewerProps) => (
  <Card>
    <CardContent className="pt-6">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h3 className="text-lg font-semibold mb-2"><Trans>Description</Trans></h3>
          <ScrollArea className="h-[150px] w-full rounded-md border p-4">
            {selectedExercise.description ? (
              <MDEditor.Markdown source={selectedExercise.description} />
            ) : (
              <p className="text-muted-foreground italic"><Trans>Description manquante</Trans></p>
            )}
          </ScrollArea>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2"><Trans>Instructions</Trans></h3>
          <ScrollArea className="h-[150px] w-full rounded-md border p-4">
            {selectedExercise.instructions ? (
              <MDEditor.Markdown source={selectedExercise.instructions} />
            ) : (
              <p className="text-muted-foreground italic"><Trans>Instructions manquantes</Trans></p>
            )}
          </ScrollArea>
        </div>
      </div>
    </CardContent>
  </Card>
);

interface ExerciseHeaderProps {
  selectedExercise: any;
  exercices: any[];
  handleExerciseSelect: (id: number) => void;
  handleVisitExercise: () => void;
  handleVisitQuiz: () => void;
  handleAddNew: () => void;
  loading: boolean;
  setIsGenerateDescriptionModalOpen: (open: boolean) => void;
  setIsDeleteModalOpen: (open: boolean) => void;
  setIsGenerateFromTranscriptModalOpen: (open: boolean) => void;
}

const ExerciseHeader = ({ selectedExercise, exercices, handleExerciseSelect, handleVisitExercise, handleVisitQuiz, handleAddNew, loading, setIsGenerateDescriptionModalOpen, setIsDeleteModalOpen, setIsGenerateFromTranscriptModalOpen }: ExerciseHeaderProps) => (
  <div className="relative flex items-center justify-between mb-4 px-4">
    <div className="flex items-center gap-4">
      {exercices?.length > 0 ? (
        <div className="flex items-center gap-2">
          <Select 
            value={selectedExercise?.id?.toString() || ""}
            onValueChange={(value) => handleExerciseSelect(Number(value))}
          >
            <SelectTrigger className="w-[200px]">
              <SelectValue placeholder={<Trans>Select an exercise</Trans>} />
            </SelectTrigger>
            <SelectContent>
              {exercices.map(ex => (
                <SelectItem key={ex.id} value={ex.id.toString()}>{ex.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
          
          <Button
            onClick={handleAddNew}
            variant="ghost"
            size="icon"
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      ) : (
        <div className="flex items-center gap-4">
          <p className="text-muted-foreground"><Trans>Pas encore d'exercices pour ce chapitre.</Trans></p>
          <Button onClick={handleAddNew}>
            <Plus className="h-4 w-4 mr-2" />
            <Trans>Ajouter un exercice</Trans>
          </Button>
        </div>
      )}
    </div>

    {selectedExercise && (
      <div className="flex items-center gap-2">
        <Button
          onClick={handleVisitExercise}
          variant="ghost"
          size="icon"
          title={<Trans>Visiter l'exercice</Trans>}
        >
          <ExternalLink className="h-4 w-4" />
        </Button>

        <Button
          onClick={() => setIsGenerateDescriptionModalOpen(true)}
          disabled={loading}
          variant="ghost"
          className="gap-2"
        >
          <Wand2 className="h-4 w-4" />
          <span><Trans>Améliorer</Trans></span>
        </Button>

        <Button
          onClick={() => setIsDeleteModalOpen(true)}
          variant="ghost"
          size="icon"
          className="text-destructive"
        >
          <Trash className="h-4 w-4" />
        </Button>
      </div>
    )}
  </div>
);

interface CodeEditorSectionProps {
  selectedExercise: any;
  handleFilenameChange: (value: string) => void;
  dispatch: (action: any) => void;
}

const CodeEditorSection = ({ selectedExercise, handleFilenameChange, dispatch }: CodeEditorSectionProps) => {
  return (
    <Card>
      <CardContent className="pt-4">
        <div className="space-y-2">
          <Select
            value={selectedExercise.files_exercice[0].filename}
            onValueChange={handleFilenameChange}
          >
            <SelectTrigger className="w-[200px]">
              <SelectValue placeholder={<Trans>Select file type</Trans>} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="main.py">main.py</SelectItem>
              <SelectItem value="script.js">script.js</SelectItem>
              <SelectItem value="index.html">index.html</SelectItem>
              <SelectItem value="styles.css">styles.css</SelectItem>
              <SelectItem value="main.php">main.php</SelectItem>
              <SelectItem value="main.java">main.java</SelectItem>
              <SelectItem value="text.txt">text.txt</SelectItem>
              <SelectItem value="query.sql">query.sql</SelectItem>
              <SelectItem value="main.c">main.c</SelectItem>
            </SelectContent>
          </Select>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="font-medium mb-1"><Trans>Code de départ:</Trans></h4>
              <CustomEditor
                height="300px"
                key={`${selectedExercise.files_exercice[0].filename}-start`}
                value={selectedExercise.files_exercice[0].code_start}
                onChange={(value) => {
                  const updatedScript = {
                    ...selectedExercise.files_exercice[0],
                    code_start: value
                  };
                  dispatch({
                    type: 'UPDATE_EXERCISE',
                    payload: {
                      ...selectedExercise,
                      files_exercice: [updatedScript]
                    }
                  });
                }}
                mode={selectedExercise.files_exercice[0].type}
              />
            </div>
            <div>
              <h4 className="font-medium mb-1"><Trans>Solution:</Trans></h4>
              <CustomEditor
                height="300px"
                key={`${selectedExercise.files_exercice[0].filename}-solution`}
                value={selectedExercise.files_exercice[0].code_solution}
                onChange={(value) => {
                  const updatedScript = {
                    ...selectedExercise.files_exercice[0],
                    code_solution: value
                  };
                  
                  dispatch({
                    type: 'UPDATE_EXERCISE',
                    payload: {
                      ...selectedExercise,
                      files_exercice: [updatedScript]
                    }
                  });
                }}
                mode={selectedExercise.files_exercice[0].type}
              />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export { ExerciseEditor, ExerciseViewer, ExerciseHeader, CodeEditorSection };