import React, { useEffect, useState } from 'react';
import {
  createSection, getUniversQuestions, updateChapterQuestions, getChapterQuestions, generateQuiz, updateVideo, createVideo } from '../../../store/actions/requests';
import { useParams, Link } from 'react-router-dom';
import { Sidebar, ShowQuiz, MainContent } from './Components.tsx';
import { useHistory } from 'react-router-dom';
import { Univers, Section, Chapter, Question, Answer, Video } from './interfaces.tsx';
import { useAtom } from 'jotai';
import { selectedChapterAtom, isLoadingAtom, selectedUniversAtom, unsavedChangesAtom } from '../../../store/atom';
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast"
import { Trans, t } from "@lingui/macro";

const fetchUniversData = async (universId: string): Promise<Univers> => {
  const response = await getUniversQuestions(universId);
  return response.data;
};

const handleCreateSectionRequest = async (name: string, universId: number): Promise<Section> => {
  const response = await createSection({ name, univers: universId });
  return response.data;
};

const VideoModal = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const [selectedChapter, setSelectedChapter] = useAtom(selectedChapterAtom);
  const modalTitle = selectedChapter?.video ? t`Modifier la vidéo` : t`Ajouter une vidéo`;
  const submitButtonText = isLoading ? t`Sauvegarde...` : t`Sauvegarder`;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{modalTitle}</DialogTitle>
        </DialogHeader>

        <form onSubmit={onSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">
              <Trans>URL / ID de la vidéo</Trans>
            </Label>
            <Input
              id="name"
              value={selectedChapter?.video?.name || ''}
              onChange={(e) => setSelectedChapter({
                ...selectedChapter,
                video: {
                  ...selectedChapter.video,
                  name: e.target.value
                }
              })}
              required
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="type">
              <Trans>Type de vidéo</Trans>
            </Label>
            <Select
              value={selectedChapter?.video?.type || 'vimeo'}
              onValueChange={(value) => setSelectedChapter({
                ...selectedChapter,
                video: {
                  ...selectedChapter.video,
                  type: value
                }
              })}
            >
              <SelectTrigger>
                <SelectValue placeholder="Sélectionner le type de vidéo" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="vimeo">Vimeo</SelectItem>
                <SelectItem value="youtube">YouTube</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex justify-end space-x-3">
            <Button
              variant="outline"
              onClick={onClose}
              type="button"
            >
              <Trans>Annuler</Trans>
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
            >
              {submitButtonText}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const AddQuiz = () => {
  const [selectedChapter, setSelectedChapter] = useAtom(selectedChapterAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [univers, setUnivers] = useAtom(selectedUniversAtom);
  const [error, setError] = useState<string | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useAtom(unsavedChangesAtom);
  const [activeTab, setActiveTab] = useState<'video' | 'exercices'| 'context' >('video');
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const { toast } = useToast();


  const removeQuestion = (questionId) => {
    const updatedChapter = {
      ...selectedChapter,
      questions: selectedChapter.questions.filter(q => q.id !== questionId)
    };

    setSelectedChapter(updatedChapter);
    setUnsavedChanges(true);
    toast({
      description: <Trans>Question supprimée. Appuyez sur sauvegarder pour enregistrer les changements</Trans>
    });
  };

  const removeAnswer = (questionId: number, answerId: number) => {
    const updatedChapter = {
      ...selectedChapter,
      questions: selectedChapter.questions.map(q => {
        if (q.id === questionId) {
          return { ...q, answers: q.answers.filter(a => a.id !== answerId) };
        }
        return q;
      })
    };

    setSelectedChapter(updatedChapter);
    setUnsavedChanges(true);
    toast({
      description: <Trans>Réponse supprimée. Appuyez sur sauvegarder pour enregistrer les changements</Trans>
    });
  };

  const params = useParams();
  const universId = params?.universId;
  const chapterId = params?.chapterId;
  const exerciceId = params?.exerciceId;

  useEffect(() => {
    const fetchData = async () => {
      if (!universId) {
        setError(t`Aucun ID univers fourni`);
        setIsLoading(false);
        return;
      }

      try {
        const [universData, chapterData] = await Promise.all([
          fetchUniversData(universId),
          chapterId ? getChapterQuestions(chapterId) : null
        ]);
        setUnivers(universData);
        if (chapterData) {
          setSelectedChapter(chapterData.data);
        }
        else {
          setSelectedChapter(null);
        }
        setIsLoading(false);
      } catch (err) {
        setError(err as string);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [universId, chapterId]);

  const handleVideoSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = selectedChapter?.video.id ? 
        await updateVideo(selectedChapter.video.id, selectedChapter.video) :
        await createVideo(selectedChapter?.video, selectedChapter.id);
      setSelectedChapter({
        ...selectedChapter!,
        video: response.data
      });
      toast({
        description: <Trans>Vidéo mise à jour avec succès</Trans>
      });
      setIsVideoModalOpen(false);
      setUnsavedChanges(true);
    } catch (err) {
      console.error('Erreur lors de la mise à jour de la vidéo:', err);
      toast({
        variant: "destructive",
        description: <Trans>Erreur lors de la mise à jour de la vidéo</Trans>
      });
    } finally {
      setIsLoading(false);
    }
  };

  const history = useHistory();
  const handleChapterSelect = async (chapter: Chapter) => {
    history.push(`/cours/${universId}/${chapter.id}`);
  };

  const onCreateSection = (name: string) => {
    if (!univers) return;
    
    handleCreateSectionRequest(name, univers.id)
      .then(newSection => {
        setUnivers({
          ...univers,
          supchapters: [...univers.supchapters, newSection]
        });
        setSelectedChapter(null);
      })
      .catch(err => {
        console.error('Erreur lors de la création de la section:', err);
      });
  };

  const addNewQuestion = (chapterId) => {
    setIsLoading(true);
    try {
      const newQuestion = {
        description: t`Nouvelle question`,
        number: selectedChapter?.questions.length ? selectedChapter.questions.length + 1 : 1,
        answers: [
          { text: "", is_valid: false, number: 1 },
          { text: "", is_valid: false, number: 2 },
          { text: "", is_valid: false, number: 3 },
          { text: "", is_valid: false, number: 4 }
        ]
      };

      const updatedChapter = {
        ...selectedChapter,
        questions: selectedChapter?.questions ? [...selectedChapter.questions, newQuestion] : [newQuestion]
      };

      setSelectedChapter(updatedChapter);
      setUnsavedChanges(true);
      toast({
        description: <Trans>Question ajoutée. Appuyez sur sauvegarder pour enregistrer les changements</Trans>
      });
    } finally {
      setIsLoading(false);
    }
  };

  const saveChanges = async () => {
    try {
      setIsLoading(true);
      const response = await updateChapterQuestions(selectedChapter.id, selectedChapter);
      setSelectedChapter(response.data);

      setUnivers({
        ...univers,
        supchapters: univers.supchapters.map(sc => ({
          ...sc,
          chapters: sc.chapters.map(ch => {
            if (ch.id === selectedChapter.id) {
              return response.data;
            }
            return ch;
          })
        }))
      });
      setUnsavedChanges(false);
      toast({
        description: <Trans>Quiz sauvegardé avec succès!</Trans>
      });
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      toast({
        variant: "destructive",
        description: <Trans>Erreur lors de la sauvegarde</Trans>
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <Sidebar 
        univers={univers}
        setUnivers={setUnivers}
        selectedChapter={selectedChapter}
        handleChapterSelect={handleChapterSelect}
        isLoading={isLoading}
        onCreateSection={onCreateSection}
      />

      <div className="ml-60 flex pl-3 pt-5">
        <MainContent 
          univers={univers}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          updateQuestion={(index, newQuestion) => {
            const newQuestions = [...selectedChapter.questions];
            newQuestions[index] = newQuestion;
            setSelectedChapter({
              ...selectedChapter,
              questions: newQuestions
            });
            setUnsavedChanges(true);
          }}
          removeAnswer={removeAnswer}
          removeQuestion={removeQuestion}
          addNewQuestion={addNewQuestion}
          saveChanges={saveChanges}
          unsavedChanges={unsavedChanges}
          setIsVideoModalOpen={setIsVideoModalOpen}
        />

        {selectedChapter && (
            <VideoModal
              isOpen={isVideoModalOpen}
              onClose={() => setIsVideoModalOpen(false)}
              onSubmit={handleVideoSubmit}
              isLoading={isLoading}
            />
        )}
      </div>
    </div>
  );
};

export default AddQuiz;