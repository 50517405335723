import React, { useState, useEffect } from 'react';
import * as requests from 'store/actions/requests.js';

const LandingDemo = ({ subDomain }) => {
  useEffect(() => {
    requests.createUserDemo(subDomain).then((res) => {
      //   TODO update the context directly
      window.location.href = '/';
    });
  }, [subDomain]);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
      <div className="h-8 w-8 animate-spin rounded-full border-4 border-white border-t-transparent" />
    </div>
  );
};

export default LandingDemo;
