import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import chapters from './chapters.json';
import 'katex/dist/katex.min.css';
import file from './chapters.pdf'

interface Chapter {
  name: string;
  context: string;
  questions: string[];
}

const PDFIngestor = () => {
  const [errorText, setErrorText] = useState('');

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="grid grid-cols-2 gap-4 max-w-[1600px] mx-auto">
        <div className="h-screen sticky top-0">
          <Card className="h-full">
            <CardHeader className="border-b py-2">
              <CardTitle className="text-xl font-bold text-gray-800">
                Document Original
              </CardTitle>
            </CardHeader>
            <CardContent className="p-0 h-[calc(100%-48px)]">
              <iframe 
                src={file}
                className="w-full h-full rounded-b-lg"
                title="PDF Original"
              />
            </CardContent>
          </Card>
        </div>

        <div>
          <div className="sticky top-0 z-10 bg-gray-100 pt-0 pb-4">
            <Card className="bg-white shadow-xl">
              <CardHeader className="text-center border-b py-3">
                <CardTitle className="text-2xl font-bold text-gray-800">
                  Liste d&apos;exercices de Maths sujet XXX
                </CardTitle>
              </CardHeader>
            </Card>
          </div>

          <div className="space-y-8 pb-24">
            {chapters.map((chapter) => (
              <Card key={chapter.name} className="shadow-lg">
                <CardHeader>
                  <CardTitle className="text-2xl font-bold text-gray-800">{chapter.name}</CardTitle>
                </CardHeader>
                <CardContent className="p-6 space-y-6">
                  <div className="bg-gray-50 p-6 rounded-lg border shadow-sm">
                    <h3 className="text-xl font-semibold mb-4 text-gray-700">Contexte</h3>
                    <div className="bg-white p-4 rounded">
                      <ReactMarkdown 
                        className="prose max-w-none"
                        remarkPlugins={[remarkGfm, remarkMath]}
                        rehypePlugins={[rehypeKatex, rehypeRaw]}
                      >
                        {chapter.context}
                      </ReactMarkdown>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold text-gray-700">Questions</h3>
                    <ol className="list-none space-y-4">
                      {chapter.questions.map((question, qIndex) => (
                        <li key={`${chapter.name}-q${qIndex}`} className="flex gap-4 items-start bg-white p-4 rounded-lg shadow-sm">
                          <span className="font-semibold text-gray-600 min-w-[2rem]">{qIndex + 1}.</span>
                          <ReactMarkdown 
                            className="prose max-w-none"
                            remarkPlugins={[remarkGfm, remarkMath]}
                            rehypePlugins={[rehypeKatex, rehypeRaw]}
                          >
                            {question}
                          </ReactMarkdown>
                        </li>
                      ))}
                    </ol>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>

          <div className="fixed bottom-0 right-0 w-1/2 bg-gray-100 py-4 px-8 flex justify-center gap-4 shadow-lg">
            <Button size="lg" className="px-8">
              Valider
            </Button>

            <Dialog>
              <DialogTrigger asChild>
                <Button variant="outline" size="lg" className="px-8">
                  Signaler une erreur
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>Signaler une erreur</DialogTitle>
                </DialogHeader>
                <div className="space-y-4 pt-4">
                  <Textarea
                    placeholder="Décrivez l'erreur que vous avez trouvée..."
                    value={errorText}
                    onChange={(e) => setErrorText(e.target.value)}
                    rows={5}
                  />
                  <Button className="w-full">
                    Envoyer
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFIngestor;
