import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAtom } from 'jotai';
import { Trans } from '@lingui/macro';
import { isLoadingAtom, selectedChapterAtom } from '../../../store/atom';
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Label } from "@/components/ui/label";
import { updateChapter } from '../../../store/actions/requests';
import { FaFilePdf } from 'react-icons/fa';

const EditChapterModal = ({ isOpen, onRequestClose }) => {
  const [isLoading] = useAtom(isLoadingAtom);
  const [selectedChapter, setSelectedChapter] = useAtom(selectedChapterAtom);
  const [fileError, setFileError] = useState('');
  const [name, setName] = useState(selectedChapter?.name || '');
  const [description, setDescription] = useState(selectedChapter?.description || '');
  const [file, setFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (name.length < 2) {
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (file) {
      formData.append('pdf', file);
    }

    try {
      const updatedChapter = await updateChapter(selectedChapter.id, formData);
      setSelectedChapter({
        ...selectedChapter,
        name,
        description,
        pdf: updatedChapter.data.pdf
      });
      onRequestClose();
      setName('');
      setDescription('');
      setFile(null);
      setFileError('');
    } catch (error) {
      console.error('Error updating chapter:', error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.type === 'application/pdf') {
        setFile(selectedFile);
        setFileError('');
      } else {
        setFile(null);
        setFileError('Seuls les fichiers PDF sont acceptés');
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onRequestClose}>
      <DialogContent className="w-3/4 max-w-4xl">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-blue-900"><Trans>Modifier le Module</Trans></DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-6 py-4">
          <div className="space-y-2">
            <Label htmlFor="name" className="text-lg font-semibold text-blue-900"><Trans>Nom du module</Trans></Label>
            <Input 
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Entrez le nom du module..."
              className="text-lg"
            />
            {name.length < 2 && name.length > 0 && (
              <p className="text-sm text-red-500"><Trans>Le nom doit contenir au moins 2 caractères.</Trans></p>
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="description" className="text-lg font-semibold text-blue-900"><Trans>Description</Trans></Label>
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Décrivez le contenu et les objectifs du module..."
              className="h-72 text-lg"
            />
           
          </div>

          <div className="space-y-4">
            <Label className="text-lg font-semibold text-blue-900"><Trans>Support de cours (PDF)</Trans></Label>
            
            {selectedChapter?.pdf && (
              <div className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg mb-2">
                <FaFilePdf className="text-red-500 text-xl" />
                <span className="text-gray-600"><Trans>Fichier actuel</Trans>: {selectedChapter.pdf.filename}</span>
                <a 
                  href={selectedChapter.pdf.file}
                  target="_blank"
                  rel="noopener noreferrer" 
                  className="text-blue-600 hover:text-blue-800"
                >
                  <Trans>Télécharger</Trans>
                </a>
              </div>
            )}

            <Input
              id="file"
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
              className="text-gray-600"
            />
            {fileError && (
              <Alert variant="destructive">
                <AlertDescription><Trans>{fileError}</Trans></AlertDescription>
              </Alert>
            )}
          </div>

          <div className="flex justify-end gap-4 pt-4">
            <Button
              type="button"
              variant="outline"
              onClick={onRequestClose}
              className="text-lg px-6"
            >
              <Trans>Annuler</Trans>
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              className="text-lg px-6 bg-blue-600 hover:bg-blue-700"
            >
              {isLoading ? <Trans>Enregistrement...</Trans> : <Trans>Enregistrer</Trans>}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

EditChapterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default EditChapterModal;
