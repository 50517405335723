import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createExercice, updateExercice, deleteExercice } from './actions/requests';

export const userAtom = atom(null);
export const isLoadingUserAtom = atom(false);


// ============= Base Storage Atoms =============
export const selectedChapterAtom = atomWithStorage('selectedChapter', null);

export const selectedSupChapterAtom = atomWithStorage('selectedSupChapter', null);
export const isLoadingAtom = atom(false);

export const selectedUniversAtom = atomWithStorage('selectedUnivers', null);

// ============= Unsaved Changes Atom =============
export const unsavedChangesAtom = atom(false);

// // Derived atom that resets unsaved changes when chapter changes
// export const unsavedChangesWithResetAtom = atom(
//   (get) => get(unsavedChangesAtom),
//   (get, set, newValue) => {
//     set(unsavedChangesAtom, newValue);
//   }
// );

// // Reset unsaved changes when chapter changes
// selectedChapterAtom.onMount = (setAtom) => {
//   const unsubscribe = selectedChapterAtom.subscribe((newChapter) => {
//     setAtom(unsavedChangesAtom, false);
//   });
//   return unsubscribe;
// };


// ============= Exercise Actions =============
export const exerciseActions = atom(null, (get, set, action) => {
  const chapter = get(selectedChapterAtom);

  switch (action.type) {
    case 'UPDATE_EXERCISE': {
      const newExercise = action.payload.data;
      const exerciseIndex = chapter.exercices.findIndex((ex) => ex.id === action.payload.id);
      if (exerciseIndex !== -1) {
        // Create new references for immutability
        const updatedExercices = chapter.exercices.map((ex) =>
          ex.id === action.payload.id ? { ...newExercise } : ex,
        );

        // Set the new state with completely new reference
        console.log({
          updatedExercices,
          chapterExercices: chapter.exercices
        })
        set(selectedChapterAtom, {
          ...chapter,
          exercices: updatedExercices,
        });
      }
      break;
    }

    case 'CREATE_EXERCISE': {
      const newExercise = {
        ...action.payload,
      };

      // Return the Promise
      return createExercice(newExercise)
        .then((response) => {
          set(selectedChapterAtom, {
            ...chapter,
            exercices: [...chapter.exercices, response.data],
          });
          return response.data; // Return the created exercise data
        })
        .catch((error) => {
          console.error('Error creating exercise:', error);
          throw error; // Propagate the error
        });
    }

    case 'DELETE_EXERCISE': {
      // Delete the exercise on the server
      return deleteExercice(action.payload)
        .then(() => {
          const filteredExercises = chapter.exercices.filter((ex) => ex.id !== action.payload);
          set(selectedChapterAtom, {
            ...chapter,
            exercices: filteredExercises,
          });
        })
        .catch((error) => {
          console.error('Error deleting exercise:', error);
          throw error; // Propagate the error
        });
    }
    case 'SAVE_EXERCISE': {
      const updatedExercise = action.payload;
      return updateExercice(updatedExercise.id, updatedExercise)
        .then((response) => {
          return response.data; // Return the updated exercise data
        })
        .catch((error) => {
          console.error('Error saving exercise:', error);
          throw error; // Propagate the error
        });
    }
    case 'ADD_ALL_EXERCISES': {
      set(selectedChapterAtom, {
        ...chapter,
        exercices: [...chapter.exercices, ...action.payload],
      });
      break;
    }

    default:
      break;
  }
});

// ============= Exercise Selectors =============
export const getExerciseById = atom((get) => (id) => {
  const chapter = get(selectedChapterAtom);
  return chapter?.exercices?.find((ex) => ex.id === id) || null;
});

export const getAllExercises = atom((get) => get(selectedChapterAtom)?.exercices || []);


