import { useState } from 'react';
import { FaTrash, FaPlus, FaFilePdf } from 'react-icons/fa';
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { Label } from "@/components/ui/label";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { FaVideo } from 'react-icons/fa';
import { CreateSectionModal, DeleteConfirmationModal } from './helpers.tsx';
import ShowExercices from './ShowExercices.tsx';
import VideoSection from "../../../components/VideoSection.tsx";
import { useAtom } from 'jotai';
import { isLoadingAtom, selectedChapterAtom } from '../../../store/atom.jsx';
import EditChapterModal from './EditChapterModal.tsx';
import Markdown from 'markdown-to-jsx';
import Sidebar from './SideBar.tsx';
import { AnswersListProps } from './interfaces.tsx';
import { Trans } from "@lingui/react/macro";

const AnswersList = ({ question, questionIndex, updateQuestion, removeAnswer, isAnyLoading }: AnswersListProps) => {
  const addNewAnswer = (questionId: number) => {
    const newAnswer = {
      text: "",
      is_valid: false,
      number: question.answers.length + 1
    };

    const newAnswers = [...question.answers, newAnswer];
    updateQuestion(questionIndex, { ...question, answers: newAnswers });
  };

  return (
    <div className="flex gap-6">
      <div className="w-1/2">
        <div className="flex items-center gap-3 mb-4">
          <span className="text-3xl font-bold text-blue-900">{questionIndex + 1}</span>
        </div>
        <Textarea
          value={question.description}
          onChange={(e) => updateQuestion(questionIndex, { ...question, description: e.target.value })}
          className="min-h-[120px] text-lg mb-4" 
          placeholder={`Entrez le texte de la question...`}
          disabled={isAnyLoading}
        />
        
        <div className="mb-6 p-5 bg-gray-50 rounded-lg shadow-sm">
          <Label className="text-base font-semibold mb-3 block"><Trans>Explication</Trans></Label>
          <div className="prose prose-sm">
            <Markdown>{question.explanation || "Aucune explication fournie"}</Markdown>
          </div>
        </div>

        <Button
          variant="outline"
          className="w-full h-14 text-base font-medium hover:bg-blue-50"
          onClick={() => addNewAnswer(question.id)}
          disabled={isAnyLoading}
        >
          <FaPlus className="mr-3 h-4 w-4" /> <Trans>Ajouter une réponse</Trans>
        </Button>
      </div>
      
      <div className="w-2/3">
        <div className="rounded-lg border shadow-sm">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px] text-center"><Trans>Correcte</Trans></TableHead>
                <TableHead><Trans>Réponse</Trans></TableHead>
                <TableHead className="w-[100px] text-center"><Trans>Actions</Trans></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {question.answers?.map((answer, answerIndex) => (
                <TableRow key={answer.id}>
                  <TableCell className="text-center">
                    <div className="flex justify-center">
                      <Checkbox
                        checked={answer.is_valid}
                        onCheckedChange={(checked) => {
                          const newAnswers = [...question.answers];
                          newAnswers[answerIndex] = { ...answer, is_valid: checked };
                          updateQuestion(questionIndex, { ...question, answers: newAnswers });
                        }}
                        disabled={isAnyLoading}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Input
                      value={answer.text}
                      onChange={(e) => {
                        const newAnswers = [...question.answers];
                        newAnswers[answerIndex] = { ...answer, text: e.target.value };
                        updateQuestion(questionIndex, { ...question, answers: newAnswers });
                      }}
                      className="w-full"
                      placeholder={`Texte de la réponse...`}
                      disabled={isAnyLoading}
                    />
                  </TableCell>
                  <TableCell className="text-center">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => removeAnswer(question.id, answer.id)}
                      className="text-red-500 hover:text-red-700 hover:bg-red-50"
                      disabled={isAnyLoading}
                    >
                      <FaTrash size={16} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

interface QuestionFormProps {
  question: Question;
  index: number;
  removeQuestion: (id: string) => void;
  updateQuestion: (index: number, question: any) => void;
  removeAnswer: (questionId: string, answerId: string) => void;
  isAnyLoading: boolean;
}

const QuestionForm = ({ question, index, removeQuestion, updateQuestion,  removeAnswer, isAnyLoading }: QuestionFormProps) => (
  <Card className="border border-blue-100 shadow-md">
    <CardContent className="space-y-4 pt-4">
      <AnswersList 
        question={question} 
        questionIndex={index}
        updateQuestion={updateQuestion}
        removeAnswer={removeAnswer}
        isAnyLoading={isAnyLoading}
      />
    </CardContent>
  </Card>
);

interface Chapter {
  id: string;
  name: string;
  questions?: Question[];
  file_resource?: {
    url: string;
    name: string;
    type: string;
  };
}

interface SupChapter {
  id: string;
  name: string;
  chapters: Chapter[];
}

interface Univers {
  id: string;
  name: string;
  supchapters: SupChapter[];
}

interface ShowQuizProps {
  selectedChapter: any;
  removeQuestion: (id: string) => void;
  updateQuestion: (index: number, question: any) => void;
  removeAnswer: (questionId: string, answerId: string) => void;
  addNewQuestion: (id: string) => void;
  isAnyLoading: boolean;
  generateQuizFromVideo: (id: string) => void;
  saveChanges: (id: string) => void;
  loadingStates: any;
  unsavedChanges: any;
  setIsVideoModalOpen: (open: boolean) => void;
}

const ShowQuiz: React.FC<ShowQuizProps> = ({
  selectedChapter,
  removeQuestion,
  updateQuestion,
  removeAnswer,
  addNewQuestion,
  isAnyLoading,
  saveChanges,
  loadingStates,
  unsavedChanges,
  setIsVideoModalOpen
}) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="w-100">
        <VideoSection 
          selectedChapter={selectedChapter}
          saveChanges={saveChanges}
          loadingStates={loadingStates}
          isAnyLoading={isAnyLoading} 
          unsavedChanges={unsavedChanges}
          setIsVideoModalOpen={setIsVideoModalOpen}
        />
      </div>

      <Card className="shadow-lg">
        <CardHeader className="border-b">
          <CardTitle className="text-2xl">{selectedChapter.name}</CardTitle>
          <Badge variant="secondary" className="text-base px-3 py-1"><Trans>Questions: {selectedChapter.questions?.length || 0}</Trans></Badge>
        </CardHeader>
        <CardContent className="space-y-6 pt-6">
          {selectedChapter.questions?.map((question, index) => (
            <QuestionForm
              key={question.id}
              question={question}
              index={index}
              removeQuestion={removeQuestion}
              updateQuestion={updateQuestion}
              removeAnswer={removeAnswer}
              isAnyLoading={isAnyLoading}
            />
          ))}

          <Button
            variant="outline"
            className="w-full h-14 text-lg font-medium"
            onClick={() => selectedChapter && addNewQuestion(selectedChapter.id)}
            disabled={isAnyLoading}
          >
            <FaPlus className="mr-3" /> <Trans>Ajouter une nouvelle question</Trans>
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

const TabButtons = ({ activeTab, setActiveTab, selectedChapter }) => (
  <Tabs value={activeTab} onValueChange={setActiveTab} className="mb-6">
    <TabsList className="justify-start bg-transparent">
      <TabsTrigger value="video" className="relative text-base px-6">
        {selectedChapter?.video && <FaVideo className="mr-2 text-green-500" />}
        <Trans>Video</Trans>
        {selectedChapter?.questions?.length > 0 && (
          <Badge variant="secondary" className="ml-2">
            {selectedChapter.questions.length}
          </Badge>
        )}
      </TabsTrigger>
      <TabsTrigger value="exercices" className="text-base px-6">
        <Trans>Exercices</Trans>
        {selectedChapter?.exercices?.length > 0 && (
          <Badge variant="secondary" className="ml-2">
            {selectedChapter.exercices.length}
          </Badge>
        )}
      </TabsTrigger>
      <TabsTrigger value="chapitre" className="text-base px-6">
        <Trans>Description</Trans>
      </TabsTrigger>
    </TabsList>
  </Tabs>
);

const MainContent = ({ activeTab, setActiveTab, updateQuestion, removeAnswer, removeQuestion, addNewQuestion, saveChanges, unsavedChanges, setIsVideoModalOpen, univers }) => {
  const [selectedChapter, setSelectedChapter] = useAtom(selectedChapterAtom);
  const [isLoading] = useAtom(isLoadingAtom);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);

  return (
    <div className="w-[100%]">
      {selectedChapter ? (
        <div className="space-y-6">
          <TabButtons activeTab={activeTab} setActiveTab={setActiveTab} selectedChapter={selectedChapter} />
          {activeTab === 'video' && (
            <ShowQuiz
              selectedChapter={selectedChapter}
              removeQuestion={removeQuestion}
              updateQuestion={updateQuestion}
              removeAnswer={removeAnswer}
              addNewQuestion={addNewQuestion}
              isLoading={isLoading}
              saveChanges={saveChanges}
              unsavedChanges={unsavedChanges}
              setIsVideoModalOpen={setIsVideoModalOpen}
            />
          )}
          {activeTab === 'chapitre' && (
            <Card className="shadow-lg">
              <CardHeader className="border-b flex flex-row items-center justify-between">
                <CardTitle className="text-2xl text-blue-900"><Trans>Informations du module</Trans></CardTitle>
                <Button
                  variant="outline"
                  onClick={() => setIsDescriptionModalOpen(true)}
                  className="px-4"
                >
                  <Trans>Modifier les informations supplémentaires</Trans>
                </Button>
              </CardHeader>
              <CardContent className="space-y-8 p-6">
                <div>
                  <h3 className="text-xl font-semibold text-blue-900 mb-3"><Trans>Nom du module</Trans></h3>
                  {selectedChapter.name ? (
                    <p className="text-lg text-blue-600">{selectedChapter.name}</p>
                  ) : (
                    <p className="text-gray-500 italic"><Trans>Aucun nom défini</Trans></p>
                  )}
                </div>

                <Separator />

                <div>
                  <h3 className="text-xl font-semibold text-blue-900 mb-3"><Trans>Resource</Trans></h3>
                  {selectedChapter.pdf ? (
                    <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg">
                      <FaFilePdf className="text-red-500 text-2xl" />
                      <Button asChild variant="link" className="text-lg">
                        <a 
                          href={selectedChapter.pdf.url}
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          {selectedChapter.pdf.name}
                        </a>
                      </Button>
                    </div>
                  ) : (
                    <p className="text-gray-500 italic"><Trans>Aucune ressource définie</Trans></p>
                  )}
                </div>

                <Separator />

                <div>
                  <h3 className="text-xl font-semibold text-blue-900 mb-3"><Trans>Description</Trans></h3>
                  {selectedChapter.description ? (
                    <div className="prose max-w-none text-lg text-blue-600">
                      <Markdown>{selectedChapter.description}</Markdown>
                    </div>
                  ) : (
                    <p className="text-gray-500 italic"><Trans>Aucune description définie</Trans></p>
                  )}
                </div>
              </CardContent>
            </Card>
          )}
          {activeTab === 'exercices' && (
            <ShowExercices />
          )}
        </div>
      ) : (
        <div className="space-y-8 p-8 bg-white rounded-lg shadow-lg">
          <div className="text-center text-blue-900 text-3xl font-bold">
            {univers?.name}
          </div>
          
          <div className="flex justify-center gap-16">
            <div className="text-center">
              <div className="text-4xl font-bold text-blue-600 mb-2">
                {univers?.supchapters?.length || 0}
              </div>
              <div className="text-lg text-gray-600"><Trans>Sections</Trans></div>
            </div>

            <div className="text-center">
              <div className="text-4xl font-bold text-blue-600 mb-2">
                {univers?.supchapters?.reduce((acc, section) => acc + section.chapters.length, 0) || 0}
              </div>
              <div className="text-lg text-gray-600"><Trans>Modules</Trans></div>
            </div>
          </div>

          <div className="text-center text-blue-600 text-xl font-medium">
            <Trans>Sélectionnez un module pour commencer à éditer son contenu</Trans>
          </div>
        </div>
      )}

      {selectedChapter && (
        <EditChapterModal
          isOpen={isDescriptionModalOpen}
          onRequestClose={() => setIsDescriptionModalOpen(false)}
        />
      )}
    </div>
  );
};

export { CreateSectionModal, QuestionForm, Sidebar, DeleteConfirmationModal, ShowQuiz, MainContent, AnswersList };
