import { useState, useEffect } from "react";
import { useHistory, useParams} from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import { Trans, t } from "@lingui/react/macro";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { selectedChapterAtom, getAllExercises, getExerciseById, exerciseActions, isLoadingAtom } from '../../../store/atom';
import { generateExercicesChapter, improveExercice } from '../../../store/actions/requests';
import DeleteConfirmationModal from './DeleteConfirmationModal.tsx';
import {ExerciseEditor, ExerciseViewer, ExerciseHeader, CodeEditorSection} from './ExerciseEditor.tsx';
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Slider } from "@/components/ui/slider";
import { Textarea } from "@/components/ui/textarea";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";


const ShowExercices = () => {
  const [, dispatch] = useAtom(exerciseActions);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useAtom(isLoadingAtom);
  const [isGenerateDescriptionModalOpen, setIsGenerateDescriptionModalOpen] = useState(false);
  const [isBatchGenerateModalOpen, setIsBatchGenerateModalOpen] = useState(false);
  const [useVideoChecked, setUseVideoChecked] = useState(false);
  const [useDescriptionChecked, setUseDescriptionChecked] = useState(false);
  const [usePdfChecked, setUsePdfChecked] = useState(false);
  const [numExercises, setNumExercises] = useState(3);
  const [additionalPrompt, setAdditionalPrompt] = useState('');
  const { toast } = useToast();

  const history = useHistory();
  const { exerciceId, chapterId, universId } = useParams();
  
  const selectedChapter = useAtomValue(selectedChapterAtom);
  const exercices = useAtomValue(getAllExercises);
  const [selectedExerciseId, setSelectedExerciseId] = useState(null);
  const getExerciseByIdFn = useAtomValue(getExerciseById);
  const selectedExercise = getExerciseByIdFn(selectedExerciseId);

  useEffect(() => {
    if (!exercices?.length) {
      setSelectedExerciseId(null);
      return;
    }

    if (exerciceId) {
      const exercise = exercices.find(ex => ex.id === Number(exerciceId));
      if (exercise) {
        setSelectedExerciseId(exercise.id);
      } else {
        history.push(`/cours/${universId}/${chapterId}/${exercices[0].id}`);
      }
    } else {
      history.push(`/cours/${universId}/${chapterId}/${exercices[0].id}`);
    }
  }, [exercices, exerciceId, universId, chapterId, history]);

  const handleExerciseSelect = (id) => {
    const exercise = exercices.find(ex => ex.id === Number(id));
    if (exercise) {
      history.push(`/cours/${universId}/${chapterId}/${exercise.id}`);
    }
  };

  const handleVisitExercise = () => {
    if (selectedExercise) {
      history.push(`/yo/exercice/${selectedExercise.id}`);
    }
  };

  const handleVisitQuiz = () => {
    if (selectedExercise) {
      history.push(`/yo/quizz/${selectedChapter.id}`);
    }
  };

  const handleGenerateSubmit = async (text) => {
    if (!selectedExercise) return;
    setLoading(true);
    try {
      const exercise = await improveExercice(
        selectedExercise.id,
        {
          context: text, 
          description: selectedChapter.description
        });
      
      dispatch({
        type: 'UPDATE_EXERCISE',
        payload: {...exercise, id: selectedExercise.id,name: selectedExercise.name}
      });
      toast({
        title: <Trans>Exercice généré</Trans>,
        description: <Trans>L'exercice a été généré avec succès</Trans>,
      });
      setIsGenerateDescriptionModalOpen(false);
    } catch (error) {
      console.error('Error generating exercise:', error);
      toast({
        title: <Trans>Erreur</Trans>,
        description: <Trans>Erreur lors de la génération de l'exercice</Trans>,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!selectedExercise) return;
    setLoading(true);
    try {
      dispatch({
        type: 'SAVE_EXERCISE',
        payload: selectedExercise
      });
      toast({
        title: <Trans>Succes</Trans>,
        description: <Trans>Exercice sauvegardé avec succés!</Trans>,
      });
    } catch (error) {
      toast({
        title: <Trans>Erreur</Trans>,
        description: <Trans>Erreur lors de la sauvegarde</Trans>,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddNew = async () => {
    if (!selectedChapter || !selectedChapter.id) {
      toast({
        title: <Trans>Erreur</Trans>,
        description: <Trans>Veuillez sélectionner un module</Trans>,
        variant: "destructive",
      });
      return;
    }

    const exerciseNumber = exercices ? exercices.length + 1 : 1;
    setLoading(true);
    
    const newExercise = {
      name: `Exercice ${exerciseNumber}`,
      description: '',
      instructions: '',
      files_exercice: [{
        filename: 'main.py',
        type: 'python',
        code_start: '',
        code_solution: '',
      }],
      chapter: selectedChapter.id
    };

    try {
      const createdExercise = await dispatch({
        type: 'CREATE_EXERCISE',
        payload: newExercise
      });
      
      toast({
        title: <Trans>Succès</Trans>,
        description: <Trans>Nouvel exercice créé avec succès!</Trans>,
      });
      
      history.push(`/cours/${universId}/${chapterId}/${createdExercise.id}`);
    } catch (error) {
      console.error('Error creating exercise:', error);
      toast({
        title: <Trans>Erreur</Trans>,
        description: <Trans>Erreur lors de la création de l'exercice</Trans>,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFilenameChange = (filename) => {
    if (!selectedExercise?.files_exercice?.[0]) return;

    const fileExtension = filename.split('.').pop();
    let type = 'text';
    
    switch(fileExtension) {
      case 'py': type = 'python'; break;
      case 'js': type = 'javascript'; break;
      case 'html': type = 'html'; break;
      case 'css': type = 'css'; break;
      case 'php': type = 'php'; break;
      case 'java': type = 'java'; break;
      case 'text': type = 'text'; break;
      case 'c': type = 'c'; break;
      default: type = 'text';
    }

    const updatedScript = {
      ...selectedExercise.files_exercice[0],
      filename,
      type
    };
    dispatch({
      type: 'UPDATE_EXERCISE',
      payload: {
        ...selectedExercise,
        files_exercice: [updatedScript]
      }
    });
  };

  const handleRemoveExercise = async () => {
    if (!selectedExercise || !selectedChapter) return;
    setLoading(true);
    try {
      await dispatch({
        type: 'DELETE_EXERCISE',
        payload: selectedExercise.id
      });
      setIsDeleteModalOpen(false);
      toast({
        title: <Trans>Succès</Trans>,
        description: <Trans>Exercice supprimé avec succès</Trans>,
      });
    } catch (error) {
      toast({
        title: <Trans>Erreur</Trans>, 
        description: <Trans>Erreur lors de la suppression</Trans>,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBatchGenerate = async () => {
    if (!selectedChapter) return;
    setLoading(true);
    try {
      let context = '';
      if (useVideoChecked && selectedChapter.video.transcript) {
        context = selectedChapter.video.transcript;
      } else if (useDescriptionChecked && selectedChapter.description) {
        context = selectedChapter.description;
      } else if (usePdfChecked && selectedChapter.pdf) {
        context = selectedChapter.pdf;
      }

      if (!context) {
        toast({
          title: <Trans>Erreur</Trans>,
          description: <Trans>Veuillez sélectionner une source de contenu valide</Trans>,
          variant: "destructive",
        });
        return;
      }

      const response = await generateExercicesChapter({
        additional_prompt: additionalPrompt, 
        description: selectedChapter.description, 
        num_exercices: numExercises, 
        transcript: selectedChapter.video.transcript, 
        use_video: useVideoChecked, 
        use_description: useDescriptionChecked,
        use_pdf: usePdfChecked,
        chapter_id: chapterId,
      });
      console.log(response)
      const exercices = response.data.exercices
      
      dispatch({
        type: 'ADD_ALL_EXERCISES',
        payload: exercices,
      });

      toast({
        title: <Trans>Succès</Trans>,
        description: <Trans>{numExercises} exercices ont été générés avec succès</Trans>,
      });

      setIsBatchGenerateModalOpen(false);
    } catch (error) {
      console.error('Error generating exercises:', error);
      toast({
        title: <Trans>Erreur</Trans>,
        description: <Trans>Erreur lors de la génération des exercices</Trans>,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white/90 backdrop-blur-sm shadow-lg p-6">
      <Dialog open={isGenerateDescriptionModalOpen} onOpenChange={setIsGenerateDescriptionModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle><Trans>Améliorer l'exercice</Trans></DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Label><Trans>Instructions supplémentaires</Trans></Label>
              <Textarea
                value={additionalPrompt}
                onChange={(e) => setAdditionalPrompt(e.target.value)}
                placeholder={`Ajoutez des instructions spécifiques pour améliorer l'exercice...`}
                className="h-24"
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsGenerateDescriptionModalOpen(false)}
            >
              <Trans>Annuler</Trans>
            </Button>
            <Button 
              onClick={() => handleGenerateSubmit(additionalPrompt)}
              disabled={loading}
            >
              {loading ? <Trans>Génération...</Trans> : <Trans>Générer</Trans>}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <DeleteConfirmationModal 
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleRemoveExercise}
      />

      <Dialog open={isBatchGenerateModalOpen} onOpenChange={setIsBatchGenerateModalOpen}>
        <DialogTrigger asChild>
          <Button className="mb-4"><Trans>Générer exercices en lot</Trans></Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle><Trans>Générer des exercices en lot</Trans></DialogTitle>
            <DialogDescription>
              <Trans>Choisissez vos options de génération</Trans>
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="flex items-center space-x-2">
              <Checkbox 
                id="useVideo" 
                checked={useVideoChecked}
                onCheckedChange={(checked) => {
                  if (checked) {
                    setUseVideoChecked(true);
                    setUseDescriptionChecked(false);
                    setUsePdfChecked(false);
                  }
                }}
                disabled={!selectedChapter?.video?.transcript}
              />
              <Label htmlFor="useVideo" className="flex items-center">
                <Trans>Générer depuis la transcription de la vidéo</Trans>
                {!selectedChapter?.video?.transcript && (
                  <span className="ml-2 text-xs text-red-500">(<Trans>Transcription indisponible</Trans>)</span>
                )}
              </Label>
            </div>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="useDescription"
                checked={useDescriptionChecked}
                onCheckedChange={(checked) => {
                  if (checked) {
                    setUseDescriptionChecked(true);
                    setUseVideoChecked(false);
                    setUsePdfChecked(false);
                  }
                }}
                disabled={!selectedChapter?.description}
              />
              <Label htmlFor="useDescription" className="flex items-center justify-between w-full">
                <span><Trans>Générer depuis la description du module</Trans></span>
                {selectedChapter?.description ? (
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button variant="ghost" size="sm" className="ml-2">
                        <Trans>Voir</Trans>
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <p className="text-sm">{selectedChapter?.description}</p>
                    </PopoverContent>
                  </Popover>
                ) : (
                  <span className="text-xs text-red-500">(<Trans>Description indisponible</Trans>)</span>
                )}
              </Label>
            </div>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="usePdf"
                checked={usePdfChecked}
                onCheckedChange={(checked) => {
                  if (checked) {
                    setUsePdfChecked(true);
                    setUseVideoChecked(false);
                    setUseDescriptionChecked(false);
                  }
                }}
                disabled={!selectedChapter?.pdf}
              />
              <Label htmlFor="usePdf" className="flex items-center">
                <Trans>Générer depuis le PDF du module</Trans>
                {!selectedChapter?.pdf && (
                  <span className="ml-2 text-xs text-red-500">(<Trans>PDF indisponible</Trans>)</span>
                )}
              </Label>
            </div>

            <div className="space-y-2">
              <Label><Trans>Nombre d'exercices</Trans> ({numExercises})</Label>
              <Slider
                value={[numExercises]}
                onValueChange={(value) => setNumExercises(value[0])}
                min={1}
                max={10}
                step={1}
              />
            </div>

            <div className="space-y-2">
              <Label><Trans>Instructions additionnelles</Trans></Label>
              <Textarea
                value={additionalPrompt}
                onChange={(e) => setAdditionalPrompt(e.target.value)}
                placeholder={`Ex: Créer des exercices progressifs, du plus simple au plus complexe...`}
              />
            </div>
          </div>

          <DialogFooter>
            <Button 
              onClick={handleBatchGenerate}
              disabled={(!useVideoChecked && !useDescriptionChecked && !usePdfChecked) || loading}
            >
              {loading ? <Trans>Génération en cours...</Trans> : <Trans>Générer</Trans>}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <ExerciseHeader 
        selectedExercise={selectedExercise}
        exercices={exercices}
        handleExerciseSelect={handleExerciseSelect}
        handleVisitExercise={handleVisitExercise}
        handleVisitQuiz={handleVisitQuiz}
        handleAddNew={handleAddNew}
        loading={loading}
        setIsGenerateDescriptionModalOpen={setIsGenerateDescriptionModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
      />

      {selectedExercise && (
        <div className="space-y-2">
         <ExerciseEditor 
                selectedExercise={selectedExercise}
                dispatch={dispatch}
              />
          {selectedExercise.files_exercice && selectedExercise.files_exercice.length > 0 && (
            <CodeEditorSection
              selectedExercise={selectedExercise}
              handleFilenameChange={handleFilenameChange}
              dispatch={dispatch}
            />
          )}

          <div className="flex justify-end">
            <Button
              onClick={handleSave}
              variant="default"
              disabled={loading}
            >
              {loading ? <Trans>Sauvegarde...</Trans> : <Trans>Sauvegarder</Trans>}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowExercices;