import { useState } from "react";
import { FaSort, FaPlus, FaTrash,  FaVideo } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
  } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
  } from '@dnd-kit/sortable';
import {SortableItem} from './helpers.tsx';
import { deleteSection, deleteChapter, reorderUnivers, getUniversQuestions, createChapter } from 'store/actions/requests.js';
import { CreateSectionModal,  DeleteConfirmationModal } from './helpers.tsx';
import {  SidebarProps, VideoFormProps } from './interfaces.tsx';
import { selectedSupChapterAtom , selectedUniversAtom, selectedChapterAtom} from 'store/atom.jsx';
import { useAtom } from 'jotai';
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { Trans } from '@lingui/macro';

const VideoForm = ({ formData, setFormData }: VideoFormProps) => {
  return (
    <>
      <div className="space-y-2">
        <Label htmlFor="name">
          <Trans>URL / ID de la vidéo</Trans>
        </Label>
        <Input
          id="name"
          value={formData.video?.name || ''}
          onChange={(e) => setFormData({
            ...formData,
            video: {
              ...formData.video,
              name: e.target.value
            }
          })}
          required
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="type">
          <Trans>Type de vidéo</Trans>
        </Label>
        <Select
          value={formData.video?.type || 'vimeo'}
          onValueChange={(value) => setFormData({
            ...formData,
            video: {
              ...formData.video,
              type: value
            }
          })}
        >
          <SelectTrigger>
            <SelectValue placeholder={<Trans>Select video type</Trans>} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="vimeo">Vimeo</SelectItem>
            <SelectItem value="youtube">YouTube</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </>
  );
};

  
const CreateModuleModal = ({ isOpen, onClose }) => {
    const [selectedSupChapter] = useAtom(selectedSupChapterAtom);
      
    const [formData, setFormData] = useState({
      name: '',
      description: '',
      video: null,
    });
    const [univers, setUnivers] = useAtom(selectedUniversAtom);
    const [error, setError] = useState('');
    const history = useHistory();
    const [selectedChapter, setSelectedChapter] = useAtom(selectedChapterAtom);
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await createChapter({...formData, supchapter: selectedSupChapter.id});
        const newChapter = response.data;
        setSelectedChapter(newChapter);
        setError('');
        history.push(`/cours/${univers.id}/${newChapter.id}`);
        onClose();
      } catch (err) {
        setError(err?.response?.data?.[0] || <Trans>Une erreur est survenue lors de la création du module</Trans>);
      }
    };

    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle><Trans>Nouveau Module</Trans></DialogTitle>
          </DialogHeader>
          
          {error && (
            <div className="mb-4 p-3 bg-destructive/10 text-destructive rounded">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="moduleName"><Trans>Nom du module</Trans></Label>
              <Input
                id="moduleName"
                value={formData.name}
                onChange={(e) => setFormData({...formData, name: e.target.value})}
                required
              />
            </div>
  
            <div className="space-y-2">
              <Label htmlFor="moduleDescription">
                <Trans>Description du module</Trans>
              </Label>
              <Textarea
                id="moduleDescription"
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
                rows={6}
                placeholder={<Trans>Description utilisée comme contexte pour les exercices...</Trans>}
              />
            </div>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="hasVideo"
                checked={formData.video !== null}
                onCheckedChange={(checked) => setFormData({...formData, video: checked ? {
                  name: '',
                  type: 0
                } : null})}
              />
              <Label htmlFor="hasVideo"><Trans>Ajouter une vidéo</Trans></Label>
            </div>
  
            {formData.video && <VideoForm formData={formData} setFormData={setFormData} />}
  
            <div className="flex justify-end mt-6">
              <div className="flex gap-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={onClose}
                >
                  <Trans>Annuler</Trans>
                </Button>
                <Button
                  type="submit"
                >
                  <Trans>Créer</Trans>
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  
  const Sidebar = ({ univers, selectedChapter, handleChapterSelect, isAnyLoading, onCreateSection,  setUnivers}: SidebarProps) => {
    const [sectionModalOpen, setSectionModalOpen] = useState(false);
    const [chapterModalOpen, setChapterModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<any>(null);
    const [isReorderMode, setIsReorderMode] = useState(false);
    const [items, setItems] = useState<any[]>([]);
    const [_, setSelectedSupChapter] = useAtom(selectedSupChapterAtom);
  
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
  
    const handleDragEnd = (event: any) => {
      const { active, over } = event;
      
      if (active.id !== over.id) {
        setItems((items) => {
          const oldIndex = items.findIndex(item => item.id === active.id);
          const newIndex = items.findIndex(item => item.id === over.id);
          
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    };
  
    const handleDelete = async (item: any, type: string) => {
      try {
        if (type === 'section') {
          await deleteSection(item.id);
          univers.supchapters = univers.supchapters.filter(s => s.id !== item.id);
        } else {
          await deleteChapter(item.id);
          const section = univers.supchapters.find(s => s.chapters.some(c => c.id === item.id));
          if (section) {
            section.chapters = section.chapters.filter(c => c.id !== item.id);
          }
        }
        setDeleteModalOpen(false);
        setItemToDelete(null);
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    };
  
    const enterReorderMode = () => {
      const flattenedItems = [];
      univers?.supchapters?.forEach(supChapter => {
        flattenedItems.push({ 
          id: `section-${supChapter.id}`,
          name: supChapter.name,
          type: 'section',
          originalData: supChapter,
          originalChapters: supChapter.chapters
        });
        supChapter.chapters.forEach(chapter => {
          flattenedItems.push({ 
            id: `chapter-${chapter.id}`,
            name: chapter.name,
            type: 'chapter',
            parentId: supChapter.id,
            originalData: chapter
          });
        });
      });
      setItems(flattenedItems);
      setIsReorderMode(true);
    };
  
    const handleReorderSave = async () => {
      try {
        const newStructure: Record<string, any> = {};
        let currentSection = null;
        
        items.forEach(item => {
          if (item.type === 'section') {
            currentSection = item.originalData.id;
            newStructure[currentSection] = {
              id: currentSection,
              name: item.name,
              chapters: []
            };
          } else if (item.type === 'chapter') {
            if (currentSection && newStructure[currentSection]) {
              newStructure[currentSection].chapters.push({
                id: item.originalData.id,
                name: item.name
              });
            }
          }
        });
  
        const payload = {
          supchapters: Object.values(newStructure).map(section => ({
            id: parseInt(section.id),
            chapters: section.chapters.map((chapter: any) => parseInt(chapter.id))
          }))
        };
  
        await reorderUnivers(univers.id, payload);
        const changes = {};
        Object.keys(newStructure).forEach(sectionId => {
          const originalSection = univers.supchapters.find(s => s.id === parseInt(sectionId));
          const newSection = newStructure[sectionId];
          
          if (originalSection.chapters.length !== newSection.chapters.length) {
            changes[sectionId] = {
              name: originalSection.name,
              originalCount: originalSection.chapters.length,
              newCount: newSection.chapters.length,
              changed: true
            };
          }
        });
  
        const updatedUnivers = await getUniversQuestions(univers.id);
        setUnivers(updatedUnivers.data);  
        setIsReorderMode(false);
      } catch (error) {
        console.error('Error saving reorder:', error);
      }
    };
  
    const handleDeleteClick = (type: string, item: any) => {
      setItemToDelete({ 
        type, 
        item,
        affectedItems: type === 'section' ? 
          item.chapters.map(chapter => ({
            name: chapter.name,
            questions: chapter.questions?.length || 0
          })) :
          item.questions?.map(question => ({
            text: question.text,
            answers: question.answers?.length || 0
          }))
      });
      setDeleteModalOpen(true);
    };
  
    const getSectionLetter = (index: number) => String.fromCharCode(65 + index);
    return (
      <div className="w-60 fixed h-full overflow-y-auto bg-background border-r">
        <div className="p-2">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold text-foreground truncate">{univers?.name || <Trans>Univers</Trans>}</h2>
            <div className="flex items-center gap-2">
              {!isReorderMode ? (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={enterReorderMode}
                  title={<Trans>Réorganiser</Trans>}
                >
                  <FaSort className="h-4 w-4" />
                </Button>
              ) : (
                <div className="flex gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setIsReorderMode(false)}
                  >
                    <Trans>Annuler</Trans>
                  </Button>
                  <Button
                    size="sm"
                    onClick={handleReorderSave}
                  >
                    <Trans>Valider</Trans>
                  </Button>
                </div>
              )}
            </div>
          </div>
  
          {!isReorderMode ? (
            <div className="space-y-2">
              <Button
                className="w-full"
                disabled={isAnyLoading}
                onClick={() => setSectionModalOpen(true)}
              >
                <FaPlus className="mr-2" /> <Trans>Nouvelle Section</Trans>
              </Button>
            </div>
          ) : null}
  
          <div className="space-y-2 mt-2">
            {!isReorderMode ? (
              univers?.supchapters?.map((supChapter, sectionIndex) => {
                const isActiveSection = supChapter.chapters.some(
                  chapter => chapter.id === selectedChapter?.id
                );
  
                return (
                  <div key={supChapter.id} className="bg-card rounded-lg p-1 shadow-sm">
                    <div className={`flex items-center justify-between p-1 rounded-md group transition-colors duration-200 ${
                      isActiveSection 
                        ? 'bg-primary/10' 
                        : 'hover:bg-accent'
                    }`}>
                      <span className="font-medium text-foreground">
                        {getSectionLetter(sectionIndex)}: {supChapter.name}
                      </span>
                      <div className="flex items-center space-x-2">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => {
                            setSelectedSupChapter(supChapter);
                            setChapterModalOpen(true);
                          }}
                          title={<Trans>Ajouter un module</Trans>}
                        >
                          <FaPlus className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleDeleteClick('section', supChapter)}
                          className="opacity-0 group-hover:opacity-100"
                          title={<Trans>Supprimer la section</Trans>}
                        >
                          <FaTrash className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
  
                    <div className="ml-2 mt-1 space-y-1">
                      {supChapter.chapters.map((chapter, chapterIndex) => {
                        const isSelected = selectedChapter?.id === chapter.id;
  
                        return (
                          <div 
                            key={chapter.id} 
                            className={`flex items-center justify-between p-1 rounded-md cursor-pointer transition-all duration-200 group
                              ${isSelected 
                                ? 'bg-primary text-primary-foreground' 
                                : 'hover:bg-accent/50'
                              }`}
                            onClick={() => handleChapterSelect(chapter)}
                          >
                            <span className={`flex items-center ${isSelected ? 'font-medium' : ''}`}>
                              {isSelected && (
                                <span className="mr-2 w-1.5 h-1.5 bg-current rounded-full" />
                              )}
                              {chapter.video_url && (
                                <FaVideo className="mr-2 text-green-500" />
                              )}
                              {chapterIndex + 1}: {chapter.name}
                            </span>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteClick('chapter', chapter);
                              }}
                              className="opacity-0 group-hover:opacity-100"
                            >
                              <FaTrash className="h-4 w-4" />
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            ) : (
              <DndContext 
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext 
                  items={items.map(item => item.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <div className="space-y-2">
                    {items.map((item) => (
                      <SortableItem key={item.id} id={item.id}>
                        <div className={`p-1 rounded-md bg-card shadow-sm border ${
                          item.type === 'section' ? 'border-primary/20' : 'ml-2 border-muted'
                        }`}>
                          <div className="flex items-center">
                            <span className="mr-2 text-muted-foreground">☰</span>
                            {item.type === 'chapter' && item.originalData.video && (
                              <FaVideo className="mr-2 text-green-500" />
                            )}
                            <span>{item.name}</span>
                          </div>
                        </div>
                      </SortableItem>
                    ))}
                  </div>
                </SortableContext>
              </DndContext>
            )}
          </div>
        </div>
  
        <CreateSectionModal
          isOpen={sectionModalOpen}
          onClose={() => setSectionModalOpen(false)}
          onCreateSection={onCreateSection}
        />
        <CreateModuleModal
          isOpen={chapterModalOpen}
          onClose={() => setChapterModalOpen(false)}
          setChapterModalOpen={setChapterModalOpen}
        />
  
        {itemToDelete && (
          <DeleteConfirmationModal
            isOpen={deleteModalOpen}
            onClose={() => {
              setDeleteModalOpen(false);
              setItemToDelete(null);
            }}
            onConfirm={() => handleDelete(itemToDelete.item, itemToDelete.type)}
            itemName={itemToDelete.item.name}
            itemType={itemToDelete.type === 'section' ? <Trans>la section</Trans> : <Trans>le module</Trans>}
            affectedItems={itemToDelete.affectedItems}
          />
        )}
      </div>
    );
  };

export default Sidebar;