import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import 'assets/scss/App.scss';
import UserContext from 'store/UserContext';

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { messages as frmessages } from "./locales/fr/messages.po";
import { messages as enmessages } from "./locales/en/messages.po";

import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import Debug from 'SigninPages/Debug/Debug';
import LandingDemo from './SignoutPages/Demo/LandingDemo.jsx';
import TestLevel from './SignoutPages/TestLevel/TestLevel.jsx';
import { Mixpanel } from './MixPanel.js';
import PDFIngestor from './SignoutPages/Generate/PDFIngestor.tsx';
import * as requests from './store/actions/requests.js';
import Slides from './SigninPages/Dashboard/Slides/Slides';
import Universe from './SigninPages/Universe/Universe.tsx';
import { Toaster } from "@/components/ui/toaster"
import FullScreenLayout from './SigninPages/Universe/FullScreenLayout.tsx'
import AddQuiz from './SigninPages/Dashboard/Admin/AddQuiz.tsx';


i18n.load("fr", frmessages);
i18n.load("en", enmessages);
i18n.activate("fr");

const Signup = lazy(() => import('SignoutPages/Signup/index.tsx'));
const LoginPage = lazy(() => import('SignoutPages/LoginPage/index.jsx'));

const ForgotPass = lazy(() => import('SignoutPages/ForgotPass/index.tsx'));
const NotFound = lazy(() => import('./components/errorPages/404.jsx'));
const MobilePage = lazy(() => import('./components/errorPages/mobilePage.jsx'));
const Exercice = lazy(() => import('./SigninPages/Exercise/Exercice.tsx'));
const Road = lazy(() => import('./SigninPages/Dashboard/Road.tsx'));
const ExerciceQuizz = lazy(() => import('./SigninPages/Exercise/ExerciceQuizz.tsx'));
const DashboardB2B = lazy(() => import('./SigninPages/DashboardB2B/DashboardB2B.jsx'));

const ActivityTable = lazy(() =>
  import('./SigninPages/DashboardB2B/components/StudentTable/ActivityTable.jsx'),
);

const AboutUs = lazy(() => import('./SignoutPages/AboutUs/AboutUs.jsx'));
const LandingPage = lazy(() => import('./SignoutPages/LandingPage/LandingPage.jsx'));
const SubscriptionPro = lazy(() => import('./SignoutPages/SubscriptionPro/SubscriptionPro.jsx'));
const AddUnivers = lazy(() => import('./SigninPages/Dashboard/Admin/AddUnivers.tsx'));

window.SYSTEM_INFORMATION = window.SYSTEM_INFORMATION ? window.SYSTEM_INFORMATION : {};
Sentry.init({
  dsn: window.SYSTEM_INFORMATION.SENTRY_DSN,
  release: `stanley@${import.meta.env.VITE_APP_VERSION || '1.0.0'}`,
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 0.1,
});

const theme = createTheme();

const storageHelper = {
  set: (key, value) => {
    return localStorage.setItem(key, value);
  },
  remove: (key) => {
    return localStorage.removeItem(key);
  },
  get: (key) => {
    return localStorage.getItem(key);
  },
};

const App = () => {
  const loggedInUser = localStorage && localStorage.getItem('user_login');

  let subDomain = window.location.hostname
    .replace('www.', '')
    .replace('dhaki.fr', '')
    .replace('appforma.fr', '')
    .replace('.', '');
  if (subDomain === 'localhost' || subDomain === 'test') subDomain = '';

  const [user, setUser] = useState();

  const isApiCallNeeded = loggedInUser === 'true' && !user;
  useEffect(() => {
    const getUserInfo = () => {
      requests
        .getCurrentUserInfo()
        .then((res) => {
          setUser({ ...res.data });
          Mixpanel.identify(res.data.email);
          Mixpanel.track(`${window.location.pathname}`);
        })
        .catch(() => {
          Mixpanel.track(`landing-page`);
          storageHelper.remove('user_login');
          Mixpanel.register({ 'First Time': 'FALSE' });
        });
    };
    if (isApiCallNeeded) {
      getUserInfo();
    }
    return function cleanup() {};
  });

  return (
    <I18nProvider i18n={i18n}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UserContext.Provider value={user}>
            <div id="app-route">
              {/* remove any dhaki branding for schools, nothing as default */}
              {!subDomain && (
                <Helmet>
                  <link rel="shortcut icon" href="static/favicon.ico" />
                  <meta property="og:site_name" content="Dhaki" />
                  <meta
                    name="description"
                    content="Pour un abonnement mensuel, tu peux
      accéder à formations complètes avec vidéos sur des langages comme Python,
      HTML, CSS, Javacript, ou encore des frameworks comme React, Django. Le tout
      depuis ton navigateur !/"
                  />
                  <title>Apprends à coder en ligne</title>
                  <meta property="og:description" content="Faites du code une passion" />
                  <meta property="og:url" content="https://www.dhaki.fr" />
                  <meta
                    property="og:image"
                    content="https://i.ibb.co/DV2BHNR/Screen-Shot-2019-12-15-at-12-42-17-AM.png"
                  />
                </Helmet>
              )}

              <BrowserRouter>
                <Suspense fallback="">
                  <Switch>
                    <Route path="/slides" component={Slides} />
                    <Route path="/cours/:universId/:chapterId?/:exerciceId?" component={AddQuiz} />
                    <Route
                      exact
                      path="/cours"
                      render={(props) => <AddUnivers {...props} user={user} />}
                    />
                    <Route path="/test-level" component={TestLevel} />
                    <Route exact path="/debug" render={(props) => <Debug {...props} />} />
                    <Route exact path="/404" component={NotFound} />
                    <Route exact path="/mobile" component={MobilePage} />
                    <Route
                      path="/:univers_identifier/quizz/:id"
                      render={(props) => (
                        <FullScreenLayout>
                          <ExerciceQuizz
                            chapterId={props.match.params.id}
                            key={props.match.params.id}
                          />
                        </FullScreenLayout>
                      )}
                    />
                    <Route
                      path="/:univers_identifier/exercice/:id"
                      render={(props) => {
                        return !user && !isApiCallNeeded ? (
                          <Redirect to="/" />
                        ) : (
                          <Exercice
                            key={props.match.params.id}
                            openVideo={props.location.search}
                            exerciceId={props.match.params.id}
                            user={user}
                          />
                        );
                      }}
                    />
                    <Route
                      path="/exercice_demo/:id"
                      render={(props) => (
                        <Exercice exerciceId={props.match.params.id} key={props.match.params.id} />
                      )}
                    />
                    <Route path="/pdf-ingestor" component={PDFIngestor} />

                    <Route exact path="/landing" component={LandingPage} />
                    <Route
                      exact
                      path="/subscription-pro"
                      render={(props) => <SubscriptionPro {...props} />}
                    />
                    <Route
                      exact
                      path="/"
                      render={(props) => {
                        if (
                          user &&
                          user.profile &&
                          user.profile.school &&
                          user.profile.school.owner === user.id
                        )
                          return <Redirect to={`/school/${user.profile.school.id}/`} />;
                        if (user) return <Redirect to="/dashboard/" />;
                        if (isApiCallNeeded) return null;
                        if (subDomain) return <LoginPage />;
                        return <LandingPage {...props} />;
                      }}
                    />
                    <Route
                      path="/students"
                      render={({ match: { url } }) => {
                        return (
                          <>
                            <Route path="/students/activity/:user_id" component={ActivityTable} />
                          </>
                        );
                      }}
                    />
                    <Route
                      path="/school/:id/"
                      render={(props) => {
                        return <DashboardB2B schoolId={props.match.params.id} />;
                      }}
                    />

                    <Route
                      path="/dashboard/"
                      render={(props) => {
                        return <Road />;
                      }}
                    />
                    <Route exact path="/about" component={AboutUs} />
                    <Route
                      path="/login"
                      render={(props) => (
                        <>
                          <LoginPage {...props} />
                        </>
                      )}
                    />
                    <Route
                      path="/signup"
                      render={(props) => (
                        <>
                          <Signup {...props} />
                        </>
                      )}
                    />
                    <Route
                      path="/reset-password"
                      render={(props) => (
                        <>
                          <ForgotPass {...props} />
                        </>
                      )}
                    />

                    <Route path="/demo" render={(props) => <LandingDemo subDomain={subDomain} />} />
                    <Route
                      path="/univers/:identifier"
                      render={(props) => (
                        <Redirect
                          to={`/${props.match.params.identifier}`}
                          key={props.match.params.identifier}
                        />
                      )}
                    />
                    <Route
                      path="/:identifier"
                      render={(props) =>
                        !user && !isApiCallNeeded ? (
                          <Redirect to="/" />
                        ) : !user ? (
                          <div className="flex items-center justify-center min-h-screen">
                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                          </div>
                        ) : (
                          <Universe
                            universIdentifier={props.match.params.identifier}
                            key={props.match.params.identifier}
                            user={user}
                          />
                        )
                      }
                    />
                  </Switch>
                </Suspense>
              </BrowserRouter>
            </div>
          </UserContext.Provider>
          <Toaster />
        </ThemeProvider>
      </StyledEngineProvider>
    </I18nProvider>
  );
};

export default App;
