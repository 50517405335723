import React, { useState, useEffect } from 'react';
import * as requests from 'store/actions/requests';
import { InfoIcon } from "lucide-react";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

const Debug = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [logs, setLogs] = useState('');
  const [analysis, setAnalysis] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await requests.getUsers();
        console.log(response.data);
        setUsers(response.data);
      } catch (err) {
        setError('Erreur lors de la récupération des utilisateurs');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const getLatestLogs = async () => {
    try {
      const response = await requests.getLatestBuildLogs();
      setLogs(response.data.logs);
    } catch (err) {
      setError('Erreur lors de la récupération des logs');
    }
  };

  const analyzeLogs = async () => {
    try {
      const response = await requests.analyzeLogs(logs);
      setAnalysis(response.data.analysis);
    } catch (err) {
      setError("Erreur lors de l'analyse des logs");
    }
  };

  const filteredLogs = logs
    .split('\n')
    .filter((line) => line.toLowerCase().includes(searchTerm.toLowerCase()))
    .join('\n');

  if (isLoading) return <div className="p-4">Chargement...</div>;
  if (error)
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );

  return (
    <div className="p-4">
      <Card className="mb-6">
        <CardHeader>
          <h2 className="text-2xl font-bold">Page de Débogage</h2>
        </CardHeader>
        <CardContent>
          <div className="mb-4 space-x-2">
            <Button
              variant="default"
              onClick={getLatestLogs}
            >
              Obtenir les derniers logs
            </Button>
            <Button
              variant="secondary"
              onClick={analyzeLogs}
              disabled={!logs}
            >
              Analyser les logs
            </Button>
          </div>
          {logs && (
            <div className="mb-4">
              <Input
                placeholder="Rechercher dans les logs"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mb-2"
              />
              <div className="h-[300px] overflow-y-auto border rounded-md p-4">
                <pre>{filteredLogs}</pre>
              </div>
            </div>
          )}
          {analysis && (
            <div className="mb-4">
              <h3 className="text-xl font-semibold mb-2">Analyse des logs</h3>
              <pre>{analysis}</pre>
            </div>
          )}
          <h3 className="text-xl font-semibold mb-4">Utilisateurs avec Plusieurs Abonnements</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {users.map((user) => (
              <Card key={user.id}>
                <CardContent className="p-4">
                  <h4 className="text-lg font-semibold mb-2">{user.email}</h4>
                  <p className="text-sm text-muted-foreground">
                    École: {user.profile.school ? user.profile.school.name : 'N/A'}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Créé le: {new Date(user.date_joined).toLocaleDateString()}
                  </p>
                  <h5 className="text-md font-semibold mt-4 mb-2">Abonnements</h5>
                  <div className="space-y-4">
                    {user.subscription_tracks.map((subscription) => (
                      <div
                        key={subscription.id}
                        className="bg-secondary p-4 rounded-lg"
                      >
                        <p className="font-medium">{subscription.track.name}</p>
                        <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
                          <p className="text-muted-foreground">
                            Début:{' '}
                            <span className="font-semibold">
                              {subscription.start_date
                                ? new Date(subscription.start_date).toLocaleDateString()
                                : 'N/A'}
                            </span>
                          </p>
                          <p className="text-muted-foreground">
                            Fin:{' '}
                            <span className="font-semibold">
                              {subscription.cancel_at
                                ? new Date(subscription.cancel_at).toLocaleDateString()
                                : 'N/A'}
                            </span>
                          </p>
                          <p className="text-muted-foreground">
                            Statut:
                            <span
                              className={`font-semibold ${
                                subscription.is_active ? 'text-green-600' : 'text-red-600'
                              }`}
                            >
                              {subscription.is_active ? 'Actif' : 'Inactif'}
                            </span>
                            {!subscription.is_active && (
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <InfoIcon className="h-4 w-4 ml-1 inline" />
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    L'utilisateur n'a pas commencé (et start n'a pas été appelé)
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            )}
                          </p>
                          <p className="text-muted-foreground">
                            Commencé:{' '}
                            <span className="font-semibold">
                              {subscription.has_started ? 'Oui' : 'Non'}
                            </span>
                          </p>
                          <p className="text-muted-foreground">
                            Crédits:{' '}
                            <span className="font-semibold">{subscription.credits_spend}</span>
                          </p>
                          <p className="text-muted-foreground">
                            Jours restants:{' '}
                            <span className="font-semibold">
                              {subscription.remaining_time_days !== null
                                ? subscription.remaining_time_days
                                : 'N/A'}
                            </span>
                          </p>
                          <p className="text-muted-foreground col-span-2">
                            Débloqué:
                            <span className="font-semibold">
                              {subscription.unlocked ? 'Oui' : 'Non'}
                            </span>
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <InfoIcon className="h-4 w-4 ml-1 inline" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  L'école a payé pour cet abonnement
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </p>
                          <p className="text-muted-foreground col-span-2">
                            Cours:
                            <span className="font-semibold">
                              {subscription.track.universes
                                ? subscription.track.universes
                                    .map((universe) => universe.name)
                                    .join(', ')
                                : 'Aucun'}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Debug;
