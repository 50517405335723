import React, { useContext, useState, lazy } from 'react';
import './styles.modules.scss';
import { Trans } from "@lingui/react/macro";

import SiteAnchor from 'assets/svg/header icon dhaki.svg?react';

import storageHelper, { showPayment } from 'utils/helpers';
import UserContext from 'store/UserContext';
import * as requests from 'store/actions/requests';

import stripePromise from 'utils/stripe';
import { Elements } from '@stripe/react-stripe-js';

const Banner = lazy(() => import('SigninPages/Exercise/BannerPayment/Banner'));

function Header() {
  const [showMenu, setshowMenu] = useState(false);
  const user = useContext(UserContext);
  const toggleMenu = () => {
    setshowMenu(!showMenu);
  };
  const handleLogout = (e) => {
    requests.logoutUser().then(() => {
      storageHelper.remove('user_login');
      window.location.href = '/';
    });
  };
  const showBanner = showPayment(user);
  const school = user && user.profile.school;

  if (!user) return <></>;
  return (
    <Elements stripe={stripePromise}>
      {showBanner && !school && <Banner backgroundColor="white" width="100%" />}
      <header
        className="col-sm-12 col-xs-12 d-flex flex-wrap"
        style={{
          backgroundColor: school && school.bg_road_left ? school.bg_road_left : 'auto',
          minHeight: '7vh',
          zIndex: 3,
        }}
      >
        <div
          className="logo-header"
          // show cursor when hover
          style={{ cursor: 'pointer' }}
          onClick={() => {
            window.location.href = '/';
            console.log('clicked');
          }}
        >
          {!school && (
            <SiteAnchor className={`siteAnchor stickyHeaderItem${showBanner ? ' active' : ''}`} />
          )}
          {school && school.logo_navbar ? (
            <img alt="Logo" src={school.logo_navbar} variant="square" sx={{ p: 0.2 }} />
          ) : (
            ''
          )}{' '}
          {school && school.name && !school.logo_navbar ? school.name : ''}
        </div>
        <svg
          onClick={() => {
            toggleMenu();
          }}
          className={`siteLogo stickyHeaderItem${showBanner ? ' active' : ''}`}
          viewBox="0 0 42 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="21"
            cy="21"
            r="19.5"
            fill="white"
            stroke="url(#paint0_linear)"
            strokeWidth="3"
          />
          <path
            d="M27 16.9149C27 20.1615 24.3339 22.8298 21 22.8298C17.6661 22.8298 15 20.1615 15 16.9149C15 13.6683 17.6661 11 21 11C24.3339 11 27 13.6683 27 16.9149Z"
            stroke="#721AFD"
            strokeWidth="4"
          />
          <path
            d="M29 32C29 27.5817 25.4183 24 21 24C16.5817 24 13 27.5817 13 32"
            stroke="#721AFD"
            strokeWidth="4"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="2.30185"
              y1="20.2599"
              x2="39.6868"
              y2="21.7495"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#B623F9" />
              <stop offset="1" stopColor="#3512FF" />
            </linearGradient>
          </defs>
        </svg>
      </header>

      {showBanner && (
        <div className={`col-xs-12 p-0 menuBar ${showMenu ? 'active' : ''}${' bannerActive'}`}>
          {!showPayment(user) && !user.profile.school && (
            <div
              to="/portal-payment"
              className="menuItem"
              onClick={() => {
                window.open('/portal-payment', '_blank');
              }}
            >
              <Trans>Paramètres Paiement</Trans>
            </div>
          )}
          <div className="menuItem" onClick={handleLogout}>
            <Trans>Déconnexion</Trans>
          </div>
        </div>
      )}
    </Elements>
  );
}

export default Header;
