import React, { useState, useEffect, lazy } from 'react';
import { UserType, UniversType } from 'utils/AppTypes';
import * as requests from 'store/actions/requests';
import { Helmet } from 'react-helmet';
import Header from '../Header';

const UniverseLayout = lazy(() => import('./UniverseLayout.tsx'));

const Univers = (props) => {
  const { user, universIdentifier } = props;

  const emptyUnivers = {
    id: null,
    univers_decouverte: null,
    supchapters: [],
    identifier: universIdentifier,
    number: 1,
    name: '',
    description: '',
  };

  const [univers, setUnivers] = useState(emptyUnivers);

  useEffect(() => {
    const fetchInitData = async (identifier) => {
      const res = await requests.getUniversByIdentifier(identifier);
      setUnivers(res.data);
    };

    fetchInitData(universIdentifier);
  }, [universIdentifier]);

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>{univers.name}</title>
      </Helmet>
      <Header showBanner />
      <main className="container mx-auto px-4 py-8">
        <UniverseLayout user={user} univers={univers} />
      </main>
    </div>
  );
};

export default React.memo(Univers);
