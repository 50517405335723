msgid ""
msgstr ""
"POT-Creation-Date: 2025-01-25 17:45+0100\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: fr\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

#: src/components/VideoSection.tsx:188
msgid "(Description indisponible)"
msgstr "(Description indisponible)"

#: src/components/VideoSection.tsx:157
msgid "(Transcription indisponible)"
msgstr "(Transcription indisponible)"

#. placeholder {0}: supChapter.name
#: src/SigninPages/Universe/ChooseSection/index.jsx:66
msgid "{0}"
msgstr "{0}"

#. placeholder {0}: subscriptionTrack.time_month
#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:29
#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:72
msgid "{0} mois"
msgstr "{0} mois"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:238
#~ msgid "{0} Suivant"
#~ msgstr "{0} Suivant"

#. placeholder {0}: supChapter.percent_completed
#: src/SigninPages/Universe/ChooseSection/index.jsx:54
msgid "{0}%"
msgstr "{0}%"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:129
msgid "{fileError}"
msgstr "{fileError}"

#: src/SigninPages/Universe/Card/index.tsx:143
msgid "{numberExercices} exercices"
msgstr "{numberExercices} exercices"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:280
msgid "{numExercises} exercices ont été générés avec succès"
msgstr "{numExercises} exercices ont été générés avec succès"

#: src/SigninPages/Dashboard/Admin/Components.tsx:75
msgid "Actions"
msgstr ""

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:398
#~ msgid "Ajouter un chapitre"
#~ msgstr "Ajouter un chapitre"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:137
msgid "Ajouter un exercice"
msgstr "Ajouter un exercice"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:398
msgid "Ajouter un module"
msgstr "Ajouter un module"

#: src/SigninPages/Dashboard/Admin/Components.tsx:237
msgid "Ajouter une nouvelle question"
msgstr ""

#: src/SigninPages/Dashboard/Admin/Components.tsx:64
msgid "Ajouter une réponse"
msgstr ""

#: src/components/VideoSection.tsx:124
#: src/SigninPages/Dashboard/Admin/SideBar.tsx:154
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:30
msgid "Ajouter une vidéo"
msgstr "Ajouter une vidéo"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:309
#~ msgid "Ajoutez des instructions spécifiques pour améliorer l'exercice..."
#~ msgstr "Ajoutez des instructions spécifiques pour améliorer l'exercice..."

#: src/SigninPages/Universe/EndToEndFullScreenLayout.tsx:36
#: src/SigninPages/Universe/EndToEndFullScreenLayout.tsx:47
msgid "Aller à la suite"
msgstr "Aller à la suite"

#: src/SigninPages/Universe/EndToEndFullScreenLayout.tsx:43
msgid "Aller au QCM"
msgstr "Aller au QCM"

#: src/SigninPages/Universe/EndToEndFullScreenLayout.tsx:45
msgid "Aller aux exercices"
msgstr "Aller aux exercices"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:161
msgid "Améliorer"
msgstr "Améliorer"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:301
msgid "Améliorer l'exercice"
msgstr "Améliorer l'exercice"

#: src/components/VideoSection.tsx:131
msgid "Améliorer Quiz"
msgstr "Améliorer Quiz"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:84
#: src/SigninPages/Dashboard/Admin/helpers.tsx:142
#: src/SigninPages/Dashboard/Admin/helpers.tsx:192
#: src/SigninPages/Dashboard/Admin/SideBar.tsx:166
#: src/SigninPages/Dashboard/Admin/SideBar.tsx:348
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:319
#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:141
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:89
msgid "Annuler"
msgstr "Annuler"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:20
msgid "Attention, confirmez bien avec votre responsable avant de commencer la formation. Un retour en arrière n'est pas possible."
msgstr "Attention, confirmez bien avec votre responsable avant de commencer la formation. Un retour en arrière n'est pas possible."

#: src/components/Quiz/Quiz.tsx:142
msgid "Attention: plusieurs réponses possibles par question."
msgstr "Attention: plusieurs réponses possibles par question."

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:154
msgid "Aucun ID univers fourni"
msgstr "Aucun ID univers fourni"

#: src/SigninPages/Dashboard/Admin/Components.tsx:313
msgid "Aucun nom défini"
msgstr ""

#: src/SigninPages/Dashboard/Admin/Components.tsx:348
msgid "Aucune description définie"
msgstr ""

#: src/SigninPages/Dashboard/Admin/Components.tsx:335
msgid "Aucune ressource définie"
msgstr ""

#: src/components/VideoSection.tsx:112
#~ msgid "Aucune vidéo n'a été ajoutée pour ce chapitre"
#~ msgstr "Aucune vidéo n'a été ajoutée pour ce chapitre"

#: src/components/VideoSection.tsx:112
msgid "Aucune vidéo n'a été ajoutée pour ce module"
msgstr "Aucune vidéo n'a été ajoutée pour ce module"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:256
msgid "Avant de regarder la solution es-tu sûr d'avoir tout essayé ?"
msgstr "Avant de regarder la solution es-tu sûr d'avoir tout essayé ?"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:91
msgid "BACK"
msgstr "BACK"

#: src/SigninPages/DynamicFooter/index.jsx:31
msgid "Besoin d'aide ?"
msgstr "Besoin d'aide ?"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:260
msgid "C'est en se trompant, puis en réessayant que l'on progresse le plus."
msgstr "C'est en se trompant, puis en réessayant que l'on progresse le plus."

#: src/SigninPages/Universe/Card/index.tsx:150
#~ msgid "Ce chapitre contient :"
#~ msgstr "Ce chapitre contient :"

#: src/components/ModalTrialEnd/ModalTrialEnd.tsx:27
#~ msgid "Ce chapitre n'est pas disponible pour la version d'essai. N'hésites pas a consulter nos offres."
#~ msgstr "Ce chapitre n'est pas disponible pour la version d'essai. N'hésites pas a consulter nos offres."

#: src/SigninPages/Universe/Card/index.tsx:150
msgid "Ce module contient :"
msgstr "Ce module contient :"

#: src/components/ModalTrialEnd/ModalTrialEnd.tsx:27
msgid "Ce module n'est pas disponible pour la version d'essai. N'hésites pas a consulter nos offres."
msgstr "Ce module n'est pas disponible pour la version d'essai. N'hésites pas a consulter nos offres."

#: src/components/ModalTrialEnd/ModalTrialEnd.tsx:15
msgid "Ceci est une version d'essai"
msgstr "Ceci est une version d'essai"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:175
msgid "Cette action supprimera également :"
msgstr "Cette action supprimera également :"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:73
msgid "Cette formation contient les cours suivants:"
msgstr "Cette formation contient les cours suivants:"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:238
#~ msgid "Chapitre Suivant"
#~ msgstr "Chapitre Suivant"

#: src/SigninPages/Universe/Course/index.tsx:68
#: src/SigninPages/Dashboard/Admin/Components.tsx:376
#~ msgid "Chapitres"
#~ msgstr ""

#: src/components/VideoSection.tsx:138
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:344
msgid "Choisissez vos options de génération"
msgstr "Choisissez vos options de génération"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:210
msgid "Code de départ:"
msgstr "Code de départ:"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:52
msgid "Code Solution"
msgstr "Code Solution"

#. placeholder {0}: subscriptionTrack.name
#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:55
msgid "Commencer la formation {0}"
msgstr "Commencer la formation {0}"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:62
msgid "Confirmation de démarrage de la formation"
msgstr "Confirmation de démarrage de la formation"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:195
msgid "Confirmer"
msgstr "Confirmer"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:87
msgid "Confirmer le démarrage"
msgstr "Confirmer le démarrage"

#: src/components/ModalTrialEnd/ModalTrialEnd.tsx:47
msgid "Continuer la démo"
msgstr "Continuer la démo"

#: src/SigninPages/DynamicFooter/index.jsx:111
msgid "Copyright"
msgstr "Copyright"

#: src/SigninPages/Dashboard/Admin/Components.tsx:73
msgid "Correcte"
msgstr ""

#: src/SigninPages/Dashboard/Road.tsx:204
msgid "Cours"
msgstr "Cours"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:145
#: src/SigninPages/Dashboard/Admin/SideBar.tsx:171
msgid "Créer"
msgstr "Créer"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:119
msgid "Créez une nouvelle section"
msgstr "Créez une nouvelle section"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:70
msgid "Date de début:"
msgstr "Date de début:"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:71
msgid "Date de fin estimée:"
msgstr "Date de fin estimée:"

#: src/SigninPages/Header/index.jsx:119
#: src/SigninPages/Dashboard/Road.tsx:187
msgid "Déconnexion"
msgstr "Déconnexion"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:20
#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:68
#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:91
#: src/SigninPages/Dashboard/Admin/Components.tsx:266
#: src/SigninPages/Dashboard/Admin/Components.tsx:342
msgid "Description"
msgstr ""

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:134
msgid "Description du module"
msgstr "Description du module"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:396
msgid "Description indisponible"
msgstr "Description indisponible"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:73
msgid "Description manquante"
msgstr "Description manquante"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:141
msgid "Description utilisée comme contexte pour les exercices..."
msgstr "Description utilisée comme contexte pour les exercices..."

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:28
msgid "Durée"
msgstr "Durée"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:72
msgid "Durée de la formation:"
msgstr "Durée de la formation:"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:148
msgid "Enregistrement..."
msgstr "Enregistrement..."

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:148
msgid "Enregistrer"
msgstr "Enregistrer"

#: src/SigninPages/Dashboard/Admin/Components.tsx:47
msgid "Entrez le texte de la question..."
msgstr ""

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:100
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:123
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:135
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:173
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:229
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:253
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:287
msgid "Erreur"
msgstr "Erreur"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:174
msgid "Erreur lors de la création de l'exercice"
msgstr "Erreur lors de la création de l'exercice"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:101
msgid "Erreur lors de la génération de l'exercice"
msgstr "Erreur lors de la génération de l'exercice"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:288
msgid "Erreur lors de la génération des exercices"
msgstr "Erreur lors de la génération des exercices"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:200
msgid "Erreur lors de la mise à jour de la vidéo"
msgstr "Erreur lors de la mise à jour de la vidéo"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:124
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:283
msgid "Erreur lors de la sauvegarde"
msgstr "Erreur lors de la sauvegarde"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:230
msgid "Erreur lors de la suppression"
msgstr "Erreur lors de la suppression"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:281
msgid "Essayer encore"
msgstr "Essayer encore"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:66
msgid "Êtes-vous sûr de vouloir commencer cette formation maintenant ? Cette action est irréversible."
msgstr "Êtes-vous sûr de vouloir commencer cette formation maintenant ? Cette action est irréversible."

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:438
#~ msgid "Ex: Créer des exercices progressifs, du plus simple au plus complexe..."
#~ msgstr "Ex: Créer des exercices progressifs, du plus simple au plus complexe..."

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:93
msgid "Exercice généré"
msgstr "Exercice généré"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:119
msgid "Exercice sauvegardé avec succés!"
msgstr "Exercice sauvegardé avec succés!"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:238
msgid "Exercice Suivant"
msgstr "Exercice Suivant"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:225
msgid "Exercice supprimé avec succès"
msgstr "Exercice supprimé avec succès"

#: src/SigninPages/Dashboard/Admin/Components.tsx:258
msgid "Exercices"
msgstr ""

#: src/SigninPages/Dashboard/Admin/Components.tsx:52
msgid "Explication"
msgstr ""

#: src/components/Quiz/Report.tsx:105
msgid "Explication pour la question "
msgstr "Explication pour la question "

#: src/components/Quiz/Report.tsx:105
#~ msgid "Explication pour la question {0}"
#~ msgstr "Explication pour la question {0}"

#: src/SigninPages/Universe/FullScreenLayout.tsx:42
msgid "Fermer"
msgstr "Fermer"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:108
msgid "Fichier actuel"
msgstr "Fichier actuel"

#: src/SigninPages/Dashboard/TrackUnlock/TrackUnlock.tsx:34
msgid "Formations incluses:"
msgstr "Formations incluses:"

#: src/components/VideoSection.tsx:219
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:448
msgid "Génération en cours..."
msgstr "Génération en cours..."

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:325
msgid "Génération..."
msgstr "Génération..."

#: src/components/VideoSection.tsx:219
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:325
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:448
msgid "Générer"
msgstr "Générer"

#: src/components/VideoSection.tsx:175
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:383
#~ msgid "Générer depuis la description du chapitre"
#~ msgstr "Générer depuis la description du chapitre"

#: src/components/VideoSection.tsx:175
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:383
msgid "Générer depuis la description du module"
msgstr "Générer depuis la description du module"

#: src/components/VideoSection.tsx:155
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:362
msgid "Générer depuis la transcription de la vidéo"
msgstr "Générer depuis la transcription de la vidéo"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:415
#~ msgid "Générer depuis le PDF du chapitre"
#~ msgstr "Générer depuis le PDF du chapitre"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:415
msgid "Générer depuis le PDF du module"
msgstr "Générer depuis le PDF du module"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:342
msgid "Générer des exercices en lot"
msgstr "Générer des exercices en lot"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:338
msgid "Générer exercices en lot"
msgstr "Générer exercices en lot"

#: src/components/VideoSection.tsx:131
msgid "Générer Quiz"
msgstr "Générer Quiz"

#: src/components/VideoSection.tsx:136
msgid "Générer un Quiz"
msgstr "Générer un Quiz"

#: src/SigninPages/Universe/Card/index.tsx:124
msgid "Gratuit"
msgstr "Gratuit"

#: src/SigninPages/Exercise/MainExercise/SideBox/ExerciseHeader/ExerciseHeader.tsx:34
#: src/SigninPages/Exercise/MainExercise/SideBox/ExerciseHeader/ExerciseHeader.tsx:39
msgid "inconnue"
msgstr "inconnue"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:65
msgid "Indices"
msgstr "Indices"

#: src/SigninPages/Dashboard/Admin/Components.tsx:298
msgid "Informations du module"
msgstr ""

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:39
#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:78
msgid "Instructions"
msgstr "Instructions"

#: src/components/VideoSection.tsx:205
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:434
msgid "Instructions additionnelles"
msgstr "Instructions additionnelles"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:83
msgid "Instructions manquantes"
msgstr "Instructions manquantes"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:305
msgid "Instructions supplémentaires"
msgstr "Instructions supplémentaires"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:94
msgid "L'exercice a été généré avec succès"
msgstr "L'exercice a été généré avec succès"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:508
msgid "la section"
msgstr "la section"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:199
msgid "LANCER LE CODE"
msgstr "LANCER LE CODE"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:508
#~ msgid "le chapitre"
#~ msgstr "le chapitre"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:508
msgid "le module"
msgstr "le module"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:86
msgid "Le nom doit contenir au moins 2 caractères."
msgstr "Le nom doit contenir au moins 2 caractères."

#: src/components/Quiz/Report.tsx:61
msgid "Ma Réponse"
msgstr "Ma Réponse"

#: src/SigninPages/Dashboard/Road.tsx:224
msgid "Mes Formations"
msgstr "Mes Formations"

#: src/components/VideoSection.tsx:124
msgid "Mise à jour..."
msgstr "Mise à jour..."

#: src/components/VideoSection.tsx:124
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:30
msgid "Modifier la vidéo"
msgstr "Modifier la vidéo"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:72
msgid "Modifier le Module"
msgstr "Modifier le Module"

#: src/SigninPages/Dashboard/Admin/Components.tsx:304
msgid "Modifier les informations supplémentaires"
msgstr ""

#. placeholder {0}: item.name
#. placeholder {1}: item.questions
#: src/SigninPages/Dashboard/Admin/helpers.tsx:180
msgid "Module \"{0}\" ({1} questions)"
msgstr "Module \"{0}\" ({1} questions)"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:238
msgid "Module Suivant"
msgstr "Module Suivant"

#: src/SigninPages/Universe/Course/index.tsx:68
#: src/SigninPages/Dashboard/Admin/Components.tsx:376
msgid "Modules"
msgstr "Modules"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:131
msgid "Nom de la section"
msgstr "Nom de la section"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:123
#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:77
#: src/SigninPages/Dashboard/Admin/Components.tsx:309
msgid "Nom du module"
msgstr ""

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:423
msgid "Nombre d'exercices"
msgstr "Nombre d'exercices"

#: src/components/VideoSection.tsx:194
msgid "Nombre de questions ({numQuestions})"
msgstr "Nombre de questions ({numQuestions})"

#: src/SigninPages/DynamicFooter/index.jsx:79
msgid "Nous appeller"
msgstr "Nous appeller"

#: src/SigninPages/DynamicFooter/index.jsx:29
msgid "NOUS CONTACTER"
msgstr "NOUS CONTACTER"

#: src/SigninPages/DynamicFooter/index.jsx:95
msgid "NOUS SUIVRE"
msgstr "NOUS SUIVRE"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:112
msgid "Nouveau Module"
msgstr "Nouveau Module"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:166
msgid "Nouvel exercice créé avec succès!"
msgstr "Nouvel exercice créé avec succès!"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:232
msgid "Nouvelle question"
msgstr "Nouvelle question"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:117
#: src/SigninPages/Dashboard/Admin/SideBar.tsx:368
msgid "Nouvelle Section"
msgstr "Nouvelle Section"

#: src/SigninPages/PdfFile/PDFViewer.tsx:82
msgid "Page {pageNumber} sur {numPages}"
msgstr "Page {pageNumber} sur {numPages}"

#: src/SigninPages/Header/index.jsx:115
#: src/SigninPages/Dashboard/Road.tsx:184
msgid "Paramètres Paiement"
msgstr "Paramètres Paiement"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:134
msgid "Pas encore d'exercices pour ce chapitre."
msgstr "Pas encore d'exercices pour ce chapitre."

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:417
msgid "PDF indisponible"
msgstr "PDF indisponible"

#: src/SigninPages/PdfFile/PDFViewer.tsx:79
msgid "Précédent"
msgstr "Précédent"

#: src/SigninPages/DynamicFooter/index.jsx:110
msgid "Propriété privé"
msgstr "Propriété privé"

#. placeholder {0}: currentQuestionIndex + 1
#. placeholder {1}: questions.length
#: src/components/Quiz/Quiz.tsx:137
msgid "Question {0} sur {1}"
msgstr "Question {0} sur {1}"

#. placeholder {0}: indexQuestion + 1
#: src/components/Quiz/Question.tsx:37
msgid "Question #{0}"
msgstr "Question #{0}"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:250
msgid "Question ajoutée. Appuyez sur sauvegarder pour enregistrer les changements"
msgstr "Question ajoutée. Appuyez sur sauvegarder pour enregistrer les changements"

#: src/components/Quiz/Quiz.tsx:165
msgid "Question précédente"
msgstr "Question précédente"

#: src/components/Quiz/Quiz.tsx:174
msgid "Question suivante"
msgstr "Question suivante"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:124
msgid "Question supprimée. Appuyez sur sauvegarder pour enregistrer les changements"
msgstr "Question supprimée. Appuyez sur sauvegarder pour enregistrer les changements"

#. placeholder {0}: item.text
#. placeholder {1}: item.answers
#: src/SigninPages/Dashboard/Admin/helpers.tsx:182
msgid "Question: \"{0}\" ({1} réponses)"
msgstr "Question: \"{0}\" ({1} réponses)"

#. placeholder {0}: selectedChapter.questions?.length || 0
#: src/SigninPages/Dashboard/Admin/Components.tsx:216
msgid "Questions: {0}"
msgstr ""

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:277
msgid "Quiz sauvegardé avec succès!"
msgstr "Quiz sauvegardé avec succès!"

#: src/components/Quiz/Report.tsx:119
msgid "Recommencer"
msgstr "Recommencer"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:337
msgid "Réorganiser"
msgstr "Réorganiser"

#: src/SigninPages/Dashboard/Admin/Components.tsx:74
msgid "Réponse"
msgstr ""

#: src/components/Quiz/Report.tsx:62
msgid "Réponse attendue"
msgstr "Réponse attendue"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:142
msgid "Réponse supprimée. Appuyez sur sauvegarder pour enregistrer les changements"
msgstr "Réponse supprimée. Appuyez sur sauvegarder pour enregistrer les changements"

#: src/SigninPages/Dashboard/Admin/Components.tsx:320
msgid "Resource"
msgstr ""

#: src/components/Quiz/Report.tsx:60
msgid "Resultat"
msgstr "Resultat"

#: src/SigninPages/Exercise/MainExercise/MainSqlRenderer/MainSqlRenderer.tsx:11
msgid "Résultat Requete"
msgstr "Résultat Requete"

#: src/components/Quiz/Report.tsx:24
msgid "Résultats"
msgstr "Résultats"

#: src/components/VideoSection.tsx:231
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:486
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:31
msgid "Sauvegarde..."
msgstr "Sauvegarde..."

#: src/components/VideoSection.tsx:231
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:486
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:31
msgid "Sauvegarder"
msgstr "Sauvegarder"

#: src/SigninPages/Exercise/MainExercise/SideBox/ExerciseHeader/ExerciseHeader.tsx:34
#: src/SigninPages/Exercise/MainExercise/SideBox/ExerciseHeader/ExerciseHeader.tsx:39
msgid "Section"
msgstr "Section"

#: src/SigninPages/Universe/Course/index.tsx:51
#: src/SigninPages/Dashboard/Admin/Components.tsx:369
msgid "Sections"
msgstr ""

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:115
msgid "Select an exercise"
msgstr "Select an exercise"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:193
msgid "Select file type"
msgstr "Select file type"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:69
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:74
msgid "Select video type"
msgstr "Select video type"

#: src/SigninPages/Dashboard/Admin/Components.tsx:381
#~ msgid "Sélectionnez un chapitre pour commencer à éditer son contenu"
#~ msgstr ""

#: src/SigninPages/Dashboard/Admin/Components.tsx:381
msgid "Sélectionnez un module pour commencer à éditer son contenu"
msgstr "Sélectionnez un module pour commencer à éditer son contenu"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:124
msgid "Solution"
msgstr "Solution"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:232
msgid "Solution:"
msgstr "Solution:"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:118
msgid "Succes"
msgstr "Succes"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:165
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:224
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:279
msgid "Succès"
msgstr "Succès"

#: src/SigninPages/PdfFile/PDFViewer.tsx:85
msgid "Suivant"
msgstr "Suivant"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:103
msgid "Support de cours (PDF)"
msgstr "Support de cours (PDF)"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:167
msgid "Suppression de {itemType}"
msgstr "Suppression de {itemType}"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:407
msgid "Supprimer la section"
msgstr "Supprimer la section"

#: src/SigninPages/Dashboard/Admin/EditChapterModal.tsx:115
msgid "Télécharger"
msgstr "Télécharger"

#: src/SigninPages/Dashboard/Admin/Components.tsx:103
msgid "Texte de la réponse..."
msgstr ""

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:364
msgid "Transcription indisponible"
msgstr "Transcription indisponible"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:56
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:61
msgid "Type de vidéo"
msgstr "Type de vidéo"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:109
msgid "Une erreur est survenue lors de la création de la section"
msgstr "Une erreur est survenue lors de la création de la section"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:104
#~ msgid "Une erreur est survenue lors de la création du chapitre"
#~ msgstr "Une erreur est survenue lors de la création du chapitre"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:104
msgid "Une erreur est survenue lors de la création du module"
msgstr "Une erreur est survenue lors de la création du module"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:330
msgid "Univers"
msgstr "Univers"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:38
#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:43
msgid "URL / ID de la vidéo"
msgstr "URL / ID de la vidéo"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:106
msgid "Utiliser"
msgstr "Utiliser"

#: src/SigninPages/Dashboard/Admin/SideBar.tsx:354
msgid "Valider"
msgstr "Valider"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorActions.tsx:216
msgid "VALIDER"
msgstr "VALIDER"

#: src/components/Quiz/Quiz.tsx:178
msgid "Valider Mes Réponses"
msgstr "Valider Mes Réponses"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:136
#~ msgid "Veuillez sélectionner un chapitre"
#~ msgstr "Veuillez sélectionner un chapitre"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:136
msgid "Veuillez sélectionner un module"
msgstr "Veuillez sélectionner un module"

#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:254
msgid "Veuillez sélectionner une source de contenu valide"
msgstr "Veuillez sélectionner une source de contenu valide"

#: src/SigninPages/Dashboard/Admin/Components.tsx:250
msgid "Video"
msgstr ""

#: src/components/VideoSection.tsx:101
msgid "Vidéo du module"
msgstr "Vidéo du module"

#: src/SigninPages/Dashboard/Admin/AddQuiz.tsx:192
msgid "Vidéo mise à jour avec succès"
msgstr "Vidéo mise à jour avec succès"

#: src/SigninPages/Dashboard/Admin/ExerciseEditor.tsx:149
msgid "Visiter l'exercice"
msgstr "Visiter l'exercice"

#: src/components/Quiz/Report.tsx:25
msgid "Voici les résultats de votre quizz."
msgstr "Voici les résultats de votre quizz."

#: src/components/VideoSection.tsx:180
#: src/SigninPages/Dashboard/Admin/ShowExercices.tsx:388
msgid "Voir"
msgstr "Voir"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:54
msgid "Voir {number} autres lignes"
msgstr "Voir {number} autres lignes"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:272
msgid "Voir la solution"
msgstr "Voir la solution"

#: src/SigninPages/Universe/EndToEndFullScreenLayout.tsx:41
msgid "Voir la vidéo"
msgstr "Voir la vidéo"

#: src/components/ModalTrialEnd/ModalTrialEnd.tsx:39
msgid "Voir nos offres"
msgstr "Voir nos offres"

#: src/SigninPages/Exercise/MainExercise/EditorBox/EditorBox.tsx:51
msgid "Votre code"
msgstr "Votre code"

#: src/SigninPages/Dashboard/Admin/helpers.tsx:169
msgid "Voulez-vous vraiment supprimer {itemName} ?"
msgstr "Voulez-vous vraiment supprimer {itemName} ?"
