import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/mode-python";
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-min-noconflict/mode-javascript";
import "ace-builds/src-min-noconflict/mode-css";
import "ace-builds/src-min-noconflict/mode-php";
import "ace-builds/src-min-noconflict/mode-java";
import "ace-builds/src-min-noconflict/mode-sql";
import "ace-builds/src-min-noconflict/mode-c_cpp";
import 'ace-builds/src-min-noconflict/theme-tomorrow_night_bright';
import "ace-builds/src-min-noconflict/mode-markdown";

const CustomEditor = ({ value, onChange, mode, height = "100%" }) => {
  return (
      <AceEditor
        fontSize={14}
        width="100%"
        theme="tomorrow_night_bright"
        mode={mode}
        value={value}
        onChange={onChange}
        height={height}
        style={{
          backgroundColor: "#040034",
          color: "#DEDEDE"
        }}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          showLineNumbers: true,
          tabSize: 2,
          showPrintMargin: false,
        }}
        wrapEnabled={true}
      />
    
  );
};

export default CustomEditor;
